<template>
  <div>
    <v-sheet
      height="100vh"
      class="overflow-y-auto"
      style="background: #e5e7eb !important"
      >
      <v-container class="pa-2" style="background: #e5e7eb; height: 100vh">
        <v-row class="mx-auto pa-2 maincontainer">
          <v-col cols="12" md="12" style="z-index: 1000 !important">
            <div
              class="pa-4 pinabg"
              style="margin:0 -.5rem;"
            >
              <v-card flat>
                <v-tabs
                  class="med-tabs"
                  v-model="tab"
                  dense
                  light
                  background-color="#f5f5f5"
                  prev-icon
                >
                  <v-tabs-slider
                    style="
                      height: 3px !important;
                      margin-top: -46px !important;
                      margin-left: 1px;
                      background: black !important;
                    "
                    color="#999"
                  ></v-tabs-slider>
                  <v-tab
                    light
                    style="
                      color: black !important;
                      font-size: 1rem !important;
                      font-weight: 400 !important;
                      padding: 0 1.5rem;
                      letter-spacing: -0.025em !important;
                    "
                    href="#subscribe"
                  >
                    <v-icon class="pr-2" small>mdi-pill</v-icon>
                    <div class="pr-12">
                      Antibiotika-Karte
                      <v-badge
                        small
                        style="margin-left: 0.5rem"
                        color="orange accent-4"
                        content="Pina"
                      ></v-badge></div></v-tab
                  ><!--<v-divider style="border-color:white!important;" inset vertical /> -->
                  <v-tab
                    light
                    align-center
                    style="
                      color: black !important;
                      font-weight: 400 !important;
                      font-size: 1rem !important;
                      letter-spacing: -0.025em !important;
                      padding: 0 1.5rem;
                      min-width: 250px;
                      border: 1px solid #e5e5e5 !important;
                      border-bottom: 0px;
                    "
                    href="#contact"
                  >
                    <v-icon class="pr-2" small>mdi-pill</v-icon>
                    <div class="pr-16">
                      Antibiotika-Karte
                      <v-badge
                        small
                        style="margin-left: 0.5rem"
                        color="primary accent-4"
                        content="ambulant"
                      ></v-badge>
                    </div>
                  </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items v-model="tab">
                  <v-tab-item :key="1" value="subscribe">
                    <v-card flat class="mt-2">
                      <v-card-title class="lstight">
                        Medikamentenliste PINA<v-spacer></v-spacer
                        ><v-spacer></v-spacer>
                        <v-text-field
                          class="searchdrugs"
                          style="
                            border: 1px solid #e5e5e5 !important;
                            font-size: ;
                          "
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Suche Medikament..."
                          dense
                          hide-details=""
                          single-line
                          rounded
                          background-color="white"
                          height="44px"
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        style="border: 1px solid #e5e5e5 !important"
                        class="pinamedtable alternate"
                        flat
                        :headers="headers"
                        :items="tablepina"
                        :search="search"
                        disable-sort
                        :items-per-page="-1"
                      ></v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :key="2" value="contact">
                    <v-card flat>
                      <v-card-title class="mt-2 pinamedtable">
                        Antiinfektive Therapie ambulant<v-spacer></v-spacer>
                      </v-card-title>
                      <table
                        class="med-table"
                        style="border-collapse: collapse !important"
                      >
                        <tbody>
                          <tr>
                            <td valign="top">
                              <strong>Ort der Infektion</strong>
                            </td>
                            <td valign="top">
                              <p><strong>Infektion und Indikation</strong></p>
                            </td>
                            <td valign="top">
                              <p><strong>Antibiotikum</strong></p>
                            </td>
                            <td valign="top">
                              <p><strong>Alternative / Erläuterung</strong></p>
                            </td>
                            <td valign="top">
                              <p><strong>Dauer</strong></p>
                            </td>
                          </tr>

                          <tr>
                            <td
                              rowspan="5"
                              style="background: #e3f2fd"
                              valign="top"
                            >
                              <p>
                                <strong
                                  >Infektionen der Atemwege und des
                                  HNO-Bereichs</strong
                                >
                              </p>
                            </td>
                            <td valign="top">
                              <p><strong>Otitis media</strong></p>

                              <p>
                                AB-Therapie nur bei Sgl. &lt;6Mo od. schlechter
                                AZ od. protrahiert &gt;48-72h od.
                                Grunderkrankung.
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Amoxicillin</strong> 50 mg/kg/d (max 3g)
                                in 3 ED<strong><br /> </strong>Primär: Analgesie
                                + Nasentropfen.
                              </p>

                              <p>
                                Otorrhoe bei Paukenröhrchen: HNO, ggf. H2O2 3%,
                                bei Foetor Ciproflox OT 7d
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Bei Rez. i.d. letzten 4 Wo: Amoxi/Clav
                                50/12,5mg/kg/d in 3 ED<br />
                                Nach Perforation: AB nur bei Otalgie,
                                Otorrhoe-Persistenz, Fieber
                              </p>
                            </td>
                            <td valign="top"><p>5 (-7) d</p></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p><strong>Pertussis</strong></p>

                              <p>
                                Immer antibiotische Behandlung zur Reduktion der
                                Symptome und der Ansteckungsgefahr
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Im 1. LM: <strong>Azithromycin </strong>10 mg/kg
                                in 1ED
                              </p>

                              <p>
                                Ab 2. LM:
                                <strong>Clarithromycin </strong>15mg/kgKG/d (max
                                1g) in 2 ED<br />
                                Nach 5d Antibiose nicht mehr ansteckend.
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                &lt;6 Monate stationäre Überwachung empfohlen.
                                Chemoprophylaxe i.d. Umgebung von Sgl. / chron.
                                krank. Kinder, siehe DGPI-Handbuch
                              </p>
                            </td>
                            <td valign="top">
                              <p>Azithr. 5d</p>

                              <p>Clarithr. 7d</p>
                            </td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p><strong>Pneumonie</strong></p>

                              <p>
                                Hinweise auf virale Genese, dann keine AB:
                                Vorschulalter und jünger, obstruktive
                                Symptomatik, langsamer Beginn, rel. guter AZ.
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Amoxicillin</strong> 50 mg/kg/d (max 3g)
                                in 3 ED Bei V.a. Mykoplasmen: Clarithr.
                                15mg/kgKG/d (max 1g) in 2 ED<br />
                                Bei &gt;8a und V.a. atypische Erreger:<br />
                                <strong>Doxycyclin</strong> d1 4mg/kgKG, max
                                200mg; ab d2 2mg/kgKG/d, max. 100 mg
                              </p>
                            </td>
                            <td valign="top">
                              <p>&lt;6 Mo Amoxi/Clav, immer i.v.</p>

                              <p>&lt;8 Wo wie NG-Sepsis</p>

                              <p>
                                &gt;8a und schlechte Compliance: Doxy<br />
                                Bei rez. Mikroaspirationen / pulm. Grunderkr:
                                AmoxiClav
                              </p>
                            </td>
                            <td valign="top">
                              <p>5(-7)d</p>

                              <p>Myk.7-10d</p>

                              <p>Doxy 7d</p>
                            </td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p>
                                <strong>Sinusitis: </strong>Primär
                                symptomatisch. Antibiose bei Persistenz (eitr.
                                Rhinosinusitis &gt;10d); schweren Symptomen
                                (eitr. Rhinosin. + &gt;39°C &gt;3d
                                +-Gesichtsschmerzen), Verschlechterung
                                (biphasischer Verlauf)
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Amoxicillin</strong> 50mg/kg/d (max 3g)
                                in 3 ED Re-eval nach 3d. Bei
                                Nichtansprechen:<strong> Amoxi/Clav </strong
                                >50mg/12,5mg/kg/d in 3 ED<br />
                                Ab 9a: <strong>Doxycyclin</strong> d1 4mg/kgKG,
                                max 200mg; ab d2 2mg/kgKG/d, max. 100 mg
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Bildgebung und HNO-Vorstellung bei V.a.
                                Komplikationen:
                              </p>

                              <p>- Schwellung periorbital / fazial</p>

                              <p>- Augenmotilitätsstörung</p>

                              <p>
                                - Jeglichem V.a. intrakr. Kompl.!<br />
                                <br />
                              </p>
                            </td>
                            <td valign="top"><p>5(-10)d</p></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p><strong>Tonsillitis / Pharyngitis</strong></p>

                              <p>
                                AB <strong>nur</strong> bei Nachweis von
                                A-Strep. Kein GAS-ST: &lt;3 Jahre (GAS bei Sgl
                                Rarität), und bei H.a. Virusinfekt (Husten,
                                Schnupfen, Konjunktivitis, Stomatitis).
                              </p>

                              <p>
                                Bei häufigen <strong>Rezidiven</strong>:
                                Cefadroxil 5d (höhere Eradikationsrate)
                              </p>
                            </td>
                            <td valign="top">
                              <p><strong>InfectoBiCicillin:</strong></p>

                              <p>
                                1-6 Jahre: 2mal täglich 1/2 Messlöffel,
                                Tagesdosis 750.000 I.E.
                              </p>

                              <p>
                                6-12 Jahre: 2mal täglich 1 Messlöffel,
                                Tagesdosis 1.500.000 I.E.
                              </p>

                              <p>
                                &gt;12 Jahre: 2mal täglich 1-2 Messlöffel,
                                Tagesdosis 1.500.000 - 3.000.000 I.E.
                              </p>
                            </td>
                            <td valign="top">
                              <p><strong>McIsaac-Score ab 3 Jahren</strong>:</p>

                              <p>Je 1 Punkt für: Alter 3-14 Jahre;</p>

                              <p>
                                Fieber; „kein Husten“; cervciale LK-Schwellung;
                                Tonsillenschwellung/-exsudat.
                                <strong>&gt;= 3 Punkte</strong>: GAS-ST.
                              </p>

                              <p>
                                Bei Allergie: Clarithromycin 15mg/kg/d in 2 ED
                              </p>
                            </td>
                            <td valign="top"><p>7d</p></td>
                          </tr>

                          <tr>
                            <td
                              rowspan="10"
                              style="background: #e8f5e9"
                              valign="top"
                            >
                              <p>
                                <strong
                                  >Haut- und Weichteilinfektionen<br /> </strong
                                >Versuch des Erregernachweises bedenken. H.a.
                                PVL? MRSA?
                              </p>
                            </td>
                            <td colspan="4" valign="top">
                              <p>
                                <strong>Lokale Antisepsis: </strong>Octenidin
                                0,1 % (Lsg/Spray, nicht in tiefe Wunden
                                einbringen) od. Chlorhexidin-Creme od. Prontosan
                                Gel (Polihexanid).
                              </p>

                              <p>
                                <strong
                                  >Panaritium, Abszess, oberflächliche Wunden,
                                  leichte Wundinfektionen, Balanitis: </strong
                                >Antiseptische und ggf. chirurgische Therapie,
                                in der Regel keine systemische AB-Therapie
                                nötig.
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p><strong>Abszess</strong></p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Amoxi/Clav </strong>50mg/12,5mg/kg/d in
                                3 ED
                              </p>
                            </td>
                            <td valign="top">
                              <p>Meist primär chirurgisch</p>
                            </td>
                            <td valign="top"><p>n. Klinik 5-7d</p></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p>
                                <strong>Impetigo Contagiosa / Phlegmone</strong>
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Cefadroxil</strong> 50mg/kg/d in 2 ED,
                                Jgdl. 2x1g
                              </p>
                            </td>
                            <td valign="top">
                              <p>Amoxi/Clav 50/12,5mg/kg/d in 3 ED</p>
                            </td>
                            <td valign="top"><p>n. Klinik 5-7d</p></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p><strong>Bisswunden</strong></p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Amoxi/Clav</strong> 50mg/12,5mg/kg/d in
                                3 ED
                              </p>
                            </td>
                            <td valign="top">
                              <p>Wundtoilette; Tetanus? Tollwut?</p>
                            </td>
                            <td valign="top"><p>5(-10)d</p></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p>
                                <strong
                                  >„Infizierter“ Insektenstich<br /> </strong
                                >keine Antibiose bei Schwellung und/oder
                                Lymphangitis &lt;48h nach Stich<br />
                                (=“verstärkte Lokalreaktion“)
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                bei Infektion:<strong
                                  ><br />
                                  Cefadroxil </strong
                                >50mg/kg/d in 2 ED, Jgdl 2x1g
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Auch bei verstärkter Lokalreaktion ohne
                                Antibiose: immer antiseptische Umschläge und
                                Ruhigstellung
                              </p>
                            </td>
                            <td valign="top">
                              <p>nach Klinik</p>

                              <p>3(-5)d</p>
                            </td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p>
                                <strong>Lymphadenitis Colli<br /> </strong
                                >Antibiose nur wenn unilateral, da bilateral
                                meist viral.
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Amoxi/Clav</strong> 50mg/12,5mg/kg/d in
                                3 ED
                              </p>

                              <p>Ggf. bis EBV ausgeschlossen: Cefadroxil</p>
                            </td>
                            <td valign="top">
                              <p>
                                Ggf. Sono: Einschmelzung? RT-23? TSpot NTM?
                                i.v.-Gabe bedenken.
                              </p>

                              <p>Bei Persistenz &gt;3Wo OP</p>
                            </td>
                            <td valign="top"><p>n. Klinik, 7d</p></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p>
                                <strong>Periorbitale Cellulitits<br /> </strong
                                >Cave bei Motilitätseinschränkung
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Amoxi/Clav </strong>50mg/12,5mg/kg/d in
                                3 ED
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Meist Beginn i.v., HNO + AA;<br />
                                p.o. nur bei mildem Befund, nur Befall Oberlid,
                                guter AZ, keine KS
                              </p>
                            </td>
                            <td valign="top">
                              <p>n. Klinik</p>

                              <p>5(-7)d</p>
                            </td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p>
                                <strong>Konjunktivitis eitrig<br /> </strong
                                >&lt;4 Wo: an Gonok. u. Chlam. denken
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Nur bei protrah. eitriger Sekr und konj. Rötung:
                                <strong>Gentamicin-AT</strong> 4x/d
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Meist gar keine Therapie nötig. Systemisch nur
                                bei Gon./Chlam.
                              </p>
                            </td>
                            <td valign="top">
                              <p>n. Klinik</p>

                              <p>4-5d</p>
                            </td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p>
                                <strong
                                  >Mykose der Haut (Z.n. Windelsoor)</strong
                                >
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Topisch:
                                <strong>Nystatin</strong> (candio-hermal) oder
                                Nystatin+Zink (Multilind)
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Windelsoor: zusätzlich Nystatin p.o.: Moronal
                                4x1ml 10-12d
                              </p>
                            </td>
                            <td valign="top"></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p><strong>Skabies</strong></p>

                              <p>
                                Bei Skabies crustosa: stationär und Derma-Konsil
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Ab 15kgKG:</strong> Ivermectin p.o.
                                0,2mg/kgED.<br />
                                &lt;15kgKG:<strong> Permethrin 5%</strong> Creme
                                8-12h (Aussparung Mund und Augen)
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                NG und Sgl.: stationär Neos Permethrin 2,5%.
                                Allgemeine Maßnahmen beachten, Kombither.
                                topisch/systemisch möglich.
                              </p>
                            </td>
                            <td valign="top">
                              <p>Rez./crustosa: Wdh nach 1 Wo</p>
                            </td>
                          </tr>

                          <tr>
                            <td
                              rowspan="2"
                              style="background: #fffde7"
                              valign="top"
                            >
                              <p><strong>Harnwege</strong></p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Zystitis<br /> </strong>keine AB bei nur
                                leichten Beschwerden, kein Fieber, unsicherer
                                Diagnose
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Nitrofurantoin </strong>(Nifuretten
                                20mg)
                              </p>

                              <p>
                                5 mg/kg KG in 3-4 ED, entsprechen täglich 3 (-4)
                                Tabletten (cave G6PD)
                              </p>
                            </td>
                            <td valign="top">
                              <p>
                                Cefaclor 40mg/kg/d in 3 ED Mädchen ab 12a und
                                &gt;50kgKG: Fosfomycin 1x3g p.o. (ED abends)
                              </p>
                            </td>
                            <td valign="top"><p>3 (-5)d</p></td>
                          </tr>

                          <tr>
                            <td valign="top">
                              <p><strong>Pyelonephritis</strong></p>
                            </td>
                            <td valign="top">
                              <p>
                                <strong>Cefpodoxim </strong>10mg/kg/d in 2 ED
                              </p>

                              <p>(max 400mg/d)</p>
                            </td>
                            <td valign="top">
                              <p>
                                Immer intravenös bei: &lt;3-6Mo; red. AZ,
                                Exsikkose, Harntraktanom.; chron. Niereninsuff;
                                transplant.
                              </p>

                              <p>
                                Bei erster PN: Sono zeitnah (muss nicht
                                stationär sein)
                              </p>
                            </td>
                            <td valign="top"><p>7(-10)d</p></td>
                          </tr>

                          <tr>
                            <td valign="top"></td>
                            <td valign="top">
                              <p><strong>Oxyuriasis</strong></p>
                            </td>
                            <td valign="top">
                              <p>
                                Pyrantel (Helmex) 10mg/kg/d p.o. 1malig ab 7.
                                LM; bei Persistenz 3x eine ED an d1 d14 d28 +
                                Behandlung der Angehörigen
                              </p>
                            </td>
                            <td valign="top">
                              <p>Mebendazol (Vermox) 1x100mg ab 2a</p>
                            </td>
                            <td valign="top"></td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>

            </div></v-col
          ></v-row
        >
      </v-container>
    </v-sheet>
  </div>
</template>

<script>

export default {
  components: {
  },

  name: "Medikamente",
  data() {
    return {
      pdfPath: "",
      search: "",
      tab: "subscribe",
      items: [
        { tabtitle: "Medikamentenliste PINA" },
        { tabtitle: "Medikamentenliste Ambulant" },
      ],
      headers: [
        { text: "Wirkstoff", value: "FIELD1" },
        { text: "Dosis, Erfahrungen, Besonderes", value: "FIELD2" },
        { text: "Bemerkungen", value: "FIELD3" },
      ],
      tablepina: [
        {
          FIELD1: "Acetylcystein",
          FIELD2:
            "Sekretolyse: p.o.(/i.v.): 10mg/kg/d  in 3 ED, max. 1200mg/d. Darmspülung: 1:10 verdünnen. PCM-Intox: Fluimucil  20%, Dosierung siehe Mühlendahl (Buch in der PINA)",
          FIELD3: "erst ab 2 Jahre",
        },
        {
          FIELD1: "Aciclovir",
          FIELD2:
            "i.v.: 30(-45)mg/kg/d  (bei Neo-Enceph 60) in 3ED als KI über 1h, max. 2,5g/d  p.o.: HSV: 75mg/kg/d  in 5 ED, max 1000mg/d; VZV >16 Jahre: 80mg/kg/d, max. 4g/d",
          FIELD3: "Dosis variiert stark nach Indikation!  NI",
        },
        {
          FIELD1: "Amikacin",
          FIELD2:
            "i.v.: 15mg/kg/d in  1ED als KI über 60 Min., max. 1,5g/d; Neo: 15mg/kg/d in 2 ED",
          FIELD3: "NI; Talspiegel vor 3. Gabe <1 mg/l",
        },
        {
          FIELD1: "Amoxicillin",
          FIELD2: "i.v./p.o.: 50(-90)mg/kg/d  in 3ED, max. 1,5-3g/d",
          FIELD3: "",
        },
        {
          FIELD1: "Amoxicillin + Clavulansäure",
          FIELD2:
            "p.o.: 40mg/10mg/kg/d  Amoxicillin/Clavulansäure in 3ED; i.v.:  75mg/kg/d Ampicillin-Anteil in 3ED, <3Mo  i.v.: 50mg/kg/d in 2 ED; Erwachsene: po 2x875 mg + 125 mg",
          FIELD3: "NI; bis 60mg/kg Amoxi-Anteil bei bei schweren  Infektionen",
        },
        {
          FIELD1: "Ampicillin + Sulbactam",
          FIELD2:
            "i.v.: 100mg/kg/d  Ampicillin-Anteil in 3ED, <3Mo 50mg/kg/d Ampi-Anteil in 2 ED  p.o.: Sultamicillin  50mg/kg/d in 2 ED; Erwachsene 375-750mg 2x/d",
          FIELD3: "NI, oral besser Amoxicillin/Clavulansäure  nutzen",
        },
        {
          FIELD1: "Amphotericin B",
          FIELD2: "Mundsoor:  p.o.:  4 x 1ml/d (4x100mg), Erw.: 4 x 1-2Tbl/d",
          FIELD3: "NI",
        },
        {
          FIELD1: "Amphotericin B  lyophilisiert",
          FIELD2:
            "Prophylaxe: iv: 2,5  mg/kg/d in 1 ED als 1 h Infusion 2x/Woche Therapie: (1-) 3 mg/kg/d in 1 ED als 2h-Infusion",
          FIELD3: "NI",
        },
        {
          FIELD1: "Ampicillin",
          FIELD2:
            "i.v.: 100mg/kg/d in  3ED; Meningitis: 200mg/kg/d in 4ED  Erwachsene und Jugendliche: 1,5-6g/d in 3 ED, max. 20g/d",
          FIELD3: "NI",
        },
        {
          FIELD1: "Azithromycin",
          FIELD2:
            "p.o.: 10mg/kg in 1  ED für 3 Tage; 5-Tage-Schema siehe  AID; Pertussis <6 Mo: 10 mg/kg  in 1 ED für 5 Tage; >6Mo: 10mg/kg  an d1, 5mg/kg an d2-5, max. 500mg",
          FIELD3: "",
        },
        {
          FIELD1: "(Benzyl-)Penicillin",
          FIELD2:
            "i.v.:  150.000IE/kg/d in 4ED, Meningitis:  300.000(-500.000)IE/kg/d in 4-6ED, max. 40Mega/d; p.o.: 50.000(100.000)IE/kg/d in 3 ED, Erwachsene 4 x 1-2Mega  InfectoBicillin: Längere  HWZ, nur 2 Einzelgaben pro Tag  Säuglinge bis 12 Monate: 2mal täglich 1/4  Messlöffel, Tagesdosis 375.000 I.E.  1-6 Jahre: 2mal täglich 1/2 Messlöffel,  Tagsdosis 750.000 I.E.  6-12 Jahre: 2mal täglich 1 Messlöffel,  Tagesdosis 1.500.000 I.E.  >12 Jahre: 2mal täglich 1-2 Messlöffel,  Tagesdosis 1.500.000-3.000.000 I.E.",
          FIELD3:
            "NI  Viele verschiedene Dosierungen je nach   Indikation, siehe Fachinfo",
        },
        {
          FIELD1: "Budenosid",
          FIELD2:
            "Aerosol: 2 x 1Hub, max.  2 x 2Hub  Susp.: < 12J: 2 x  0,5-1mg max. 2mg/d ; >12J: 2 x 1-2mg/d, max. 4mg/d",
          FIELD3: "",
        },
        {
          FIELD1: "Butylscopolamin",
          FIELD2:
            "p.o.:  0,3-0,5mg/kg/ED, max. 3/d; >6LJ: 10(-20)mg 3x/d i.v.: 0,3-0,6mg/kg/ED",
          FIELD3: "Dragees à 10mg",
        },
        {
          FIELD1: "Captopril",
          FIELD2:
            "p.o.: 0,5-3-6mg/kg/d in 2-3ED, Start immer mit  0,1mg/kg, cave RR nach erster Gabe.  Nachlastsenkung: bis 1mg/kg/d, Hypertonus: bis  5mg/kg/d.  Erwachsene: 12,5 - (150)mg/die in 3ED bzw:  25-50mg/m²/d in 3 ED",
          FIELD3: "NI",
        },
        {
          FIELD1: "Cefadroxil",
          FIELD2: "p.o.: 50(-100)  mg/kg/d in 2 ED; >12a: 2x1(-2)g",
          FIELD3: "",
        },
        {
          FIELD1: "Cefaclor",
          FIELD2:
            "p.o.: 40mg/kg/d in  3ED, max. 1g/d, Prophylaxe HWI 10 mg/kg/d (off-label!). Erwachsene: 3x500 mg",
          FIELD3: "",
        },
        {
          FIELD1: "Cefazolin",
          FIELD2: "i.v.: 50-100mg/kg/d  in 3 ED",
          FIELD3: "",
        },
        {
          FIELD1: "Cefepim",
          FIELD2:
            "i.v.: ≤ 3 Monate 60  mg/kg/d in 2 ED. >3 Mo: 100 mg/kg/d in 2 ED; schwere Inf. 150 mg/kg/d in 3  ED; Erwachsene: i.v. 3 x 2g/d",
          FIELD3: "NI",
        },
        {
          FIELD1: "Cefotaxim",
          FIELD2:
            "i.v.: 100mg/kg/d in  3 ED. Sepsis: 150-200mg/kg/d in 3  ED;  Meningitis/Epiglottitis: 200mg/kg/d in  4ED (Erwachsene: 4 x 2-3g/d) max. 12g/d",
          FIELD3: "NI",
        },
        {
          FIELD1: "Cefpodoxim-proxetil",
          FIELD2: "p.o. 10mg/kg in 2  ED, Jugendliche 2x200mg",
          FIELD3: "",
        },
        {
          FIELD1: "Ceftazidim",
          FIELD2:
            "i.v.: 100(-150)mg/kg/d  in 3ED, <8 Wochen: 60mg/kg/d in  3ED Erwachsene: 3 x 1-2g/d, max. 9g/d. CF: 3 x 3g/d",
          FIELD3: "NI",
        },
        {
          FIELD1: "Ceftriaxon",
          FIELD2:
            "i.v.: 75 mg/kg/d in  1 ED als KI; Meningitis: 1. Dosis  100mg/kg/d in 1 ED als KI. Max. 2g/d. Erwachsene: 1 x   2g/d, max. 4g/d",
          FIELD3: "NI, GI, Kurzinfusion mind. 30min",
        },
        {
          FIELD1: "Cefuroxim",
          FIELD2:
            "i.v.: 100mg/kg/d in  3ED; Erwachsene:3x750-1500 mg, max 4,5g/d;  p.o.: 30(-50)mg/kg/d  in 2ED, Alternativen bedenken!",
          FIELD3:
            "NI. Oral: Bioverfügbarkeit nur 20-50% und  hoher ESBL-Selektionsdruck.",
        },
        {
          FIELD1: "Cetirizin",
          FIELD2:
            "p.o.: ab 2 J.:  <30kg: 5mg; >30kg: 10mg in 1-2 ED. Tr.: 10mg in 20 gtt; Saft:10mg/10ml",
          FIELD3: "NI; Zugelassen erst ab 2 Jahren.",
        },
        {
          FIELD1: "Chloralhydrat",
          FIELD2:
            "p.o.:  (25-)50mg/kg/ED = 0,25-0,5ml/kg der 10%-Lösg., max. 4-stdl. Rectal: (25-)50mg/kg/ED, max. 4-stdl.",
          FIELD3: "",
        },
        {
          FIELD1: "Clindamycin",
          FIELD2:
            "i.v.: 30mg/kg/d in  3ED als KI; >12 Jahre: 1,2g-2,8g/d in 3ED als KI  p.o.: 30mg/kg/d in 3  ED; >12 Jahre: 1,2g-1,8g in 3ED",
          FIELD3: "gute Bioverfügbarkeit >90%, fast immer  orale Gabe möglich",
        },
        {
          FIELD1: "Clarithromycin",
          FIELD2:
            "i.v./p.o.:  Sgl.:  10mg/kg/d in 2ED; Ab 1J: 15mg/kg/d in 2ED; ab 28kgKG: 2x 250mg; max. 2x  500mg. Möglichst oral geben, gute Resorption.",
          FIELD3: "NI",
        },
        {
          FIELD1: "Clonazepam",
          FIELD2:
            "1ml  Clonazepam + 1ml Aqua (liegt bei) ergibt 1mg in 2 ml. Langsam i.v..; Sgl: 1/2  Amp. (=0,5mg); Kinder: 1/21 Ampulle (0,5-1mg); Erwachsene 1-2 Ampullen  (1-2mg)",
          FIELD3: "Cave Hypersalivation",
        },
        {
          FIELD1: "Co-trimoxazol = Trim. + Sulfameth.1:5",
          FIELD2:
            "(i.v.)/p.o.: 5-6mg/kg/d  TMP-Anteil in 2ED. Erwachsene/Jugendl: 2 x 160mg/d Pneumocystis: 20mg/kg/d in 3-4ED, Beginn i.v.",
          FIELD3:
            "NI; orale Bioverfügbarkeit >95%, intravenös  fast nie nötig. Cave G6PD.",
        },
        {
          FIELD1: "Dexamethason",
          FIELD2:
            "Pseudokrupp  Infectodexakrupp-Saft 0,15 mg/kg (0,4 ml/kg) bakterielle Meningitis:  i.v. 0,8 mg/kg/d in 4 ED für 2  Tage",
          FIELD3: "",
        },
        {
          FIELD1: "Dimenhydrinat (Vomex)",
          FIELD2:
            "i.v.: 1(-2)mg/kg/ED  6-8-stdl.; p.o.: 5mg/kg/d in 3-4ED  rektal: <25kg:  40mg/ED; >25kg: 70mg/ED; Erwachsene: 150mg/ED max. 3x/d",
          FIELD3: "erst ab 3 Jahre",
        },
        {
          FIELD1: "Dimetindenmaleat",
          FIELD2:
            "i.v. bei  Anaphylaxie:  0,1mg/kg/ED  p.o.: 0,05-0,1  mg/kg/d; 1-8Jahre 3x10-15gtt; >9a 3x15-20gtt; Erwachsene 3x20-40gtt",
          FIELD3:
            "Erst ab 1 Jahr zugelassen  Cetirizin hat weniger Nebenwirkungen",
        },
        {
          FIELD1: "Doxycyclin",
          FIELD2:
            "p.o./i.v.(KI  über 1-3h).:  2mg/kg/d, erster Tag: 4mg/kg; bei schwerer Erkrankung auf hoher Dosis  bleiben. Jugendliche / Erwachsene: 100mg/d in 1ED, erster Tag: 200mg in 1ED",
          FIELD3:
            "NI; Infusion über 1-3h; fast immer oral  möglich; starke Venenreizung;  Nicht zu Milchprodukten einnehmen",
        },
        {
          FIELD1: "Epinephrin",
          FIELD2:
            "Intramuskulär:  Fastjekt-Autoinjektor: >25 kgKG: 300 μg; 7,5 – 25 kgKG: Fastjekt-junior  150 μg; < 7,5 kgKG: Suprarenin  i.m.: 0,01mg/kgKG, = 0,01ml/kgKG der 1:1000 Lösung; REA: 0,01mg/kg alle 3-5min, verdünnte Spritze 1:10000 von Intensiv,  hiervon 0,1ml/kgKG",
          FIELD3:
            "EpiPen: lat. Oberschenkel  Supra i.v. nur bei Reanimation oder auf  Intensiv!  Falls nötig ggf. Herzalarm!",
        },
        {
          FIELD1: "Erythromycin",
          FIELD2:
            "i.v.: 20(-40)mg/kg/d in 2ED, Venenreizung! Möglichst oral geben, gute  Resorption. Erwachsene: 2 x 1g/d, max. 2 x 2g. Darmstimulation i.v.: 3mg/kg/ED 3 x tgl.  p.o.: Erythromycinestolat:40mg/kg/d  in 2ED; Erwachsene: 2 x 1g/d;",
          FIELD3: "NI  Erythromycinsuccinat: schlechter verträglich",
        },
        {
          FIELD1: "Flucloxacillin",
          FIELD2:
            "i.v./p.o.: 70-100mg/kg/d  in 3ED; Jugendliche/Erwachsene:  i.v.3x1-2g/d max. 8g/d; p.o.: 3x1g",
          FIELD3: "NI, max. Tagesdosis i.v.: 8-12g",
        },
        {
          FIELD1: "Furosemid",
          FIELD2:
            "i.v./p.o.:  0,5-1,0-...mg/kg/ED 3-6 x tgl., max. 10mg/kg/d  bei Kindern >10kg vorsichtig dosieren  (RR-Abfall), Beginn mit 5-10mg/ED",
          FIELD3: "Langsam i.v., Gefahr der Ototoxizität",
        },
        {
          FIELD1: "Gentamicin",
          FIELD2:
            "i.v.: 5mg/kg/d in  1ED als KI 1h (Neo <2kg: 3,5mg/kgKG); bei Meningitis >2.LW 7mg/kg/d;  Erwachsene: 2 x 80mg/d, max. 400mg/d; Dauer selten länger als 3-5 Tage",
          FIELD3: "NI, Spiegel vor 3. Gabe <=1 mg/l  Cave G6PD",
        },
        {
          FIELD1: "Hydrocortison",
          FIELD2:
            "i.v./p.o.: 1-10-25mg/kg/ED 4-6 x tgl. je nach Indikation  AGS: p.o.: 15-20mg/kg/m2/d in 3ED, siehe  Stress-schema AGS Addison-Krise:  100mg/m2 i.v, dann 30-45mg/m2/d in 4 ED",
          FIELD3: "Bei  längerer Anwendung ausschleichen",
        },
        {
          FIELD1: "Ibuprofen",
          FIELD2:
            "p.o./spp.:  10mg/kg/ED;  max. 30 mg/kg/d  Supp erst ab 3 Mo und ab 6kg; Saft erst ab 6Mo  und 5kg",
          FIELD3: "",
        },
        {
          FIELD1: "Ipratropiumbromid",
          FIELD2:
            "Inhal.: 1ml = 10 Hübe  = 30 Tropfen = 0,25mg. Gabe alle (4-)6h; Notfall: bis zu  3x in 1h! <6J: 0,1-0,25mg =12-30 Tr.; 6-12J:0,25mg = 30 Tr; Erwachsene:  0,5mg= 60 Tr",
          FIELD3:
            'Cave:  "Atrovent N" ist nicht in Tr dosierbar, hier 1 Hub =0,02mg.',
        },
        {
          FIELD1: "Lorazepam",
          FIELD2:
            "i.v.:  0,05(-0,1)mg/kg/ED  p.o.: 0,05mg/kg/ED,  im KA buccal: >4 Monate 0,5 mg, >15 kg 1 mg, > 25kg 2,5 mg",
          FIELD3:
            "i.v.-Lösung im Kühlschrank, muss 1:1 verdünnt  werden mit Aqua oder NaCl",
        },
        {
          FIELD1: "Macrogol",
          FIELD2:
            "p.o.: >6Monate Laxbene  2 Beutel tgl; >2 J Movicol jun. 2x2 Beutel initial",
          FIELD3: "Dosierung nach Wirkung!",
        },
        {
          FIELD1: "Mebendazol",
          FIELD2:
            "Oxyuriasis:  p.o.: 100mg  1x/d für 1(-3) Tage, nur >2 Jahre, ggf. Wdh. nach 3 Wochen",
          FIELD3: "",
        },
        {
          FIELD1: "Meropenem",
          FIELD2:
            "i.v.: 60mg/kg/d in  3ED als KI; Meningitis / Sepsis >3 Mo: 120mg/kg/d in 3 ED als KI;  Erwachsene: 3 x 1g, max. 6g/d",
          FIELD3: "NI;",
        },
        {
          FIELD1: "Metamizol (Novalgin)",
          FIELD2:
            "KI über  10min (nie als i.v.-Bolus!)/p.o./rek/i.m.: 10-15mg/kg/ED max. 6-stdl.",
          FIELD3: "ab 5kg/3Mo, davor off-label, Cave G6PD",
        },
        {
          FIELD1: "Metronidazol",
          FIELD2:
            "i.v./p.o.: 30mg/kg/d in  3ED als KI; p.o. Ju/Erw. 30mg/kg/d in 3 ED  <8 Wo 15mg/kg/d in ED Loading-Dose, danach  in 2 ED; >12a: 3x500mg als KI",
          FIELD3: "NI",
        },
        {
          FIELD1: "Mezlozillin",
          FIELD2:
            "<3kgKG:  150mg/kg/d  in 2 ED als KI in 30min; >3kgKG: 225mg/kg/d  in 3 ED als KI",
          FIELD3: "KI über 30 min",
        },
        {
          FIELD1: "Midazolam",
          FIELD2:
            "Sedierung  /Krampfanfall: i.v.: 0,1mg/kg/ED; intranasal  0,2mg/kg/ED; buccal 0,2-0,5mg/kg; Buccolam: 312Mo 2,5mg; 1-4Jahre  5mg; 5-9 Jahre 7,5mg; ab 10Jahre 10mg; Prämedikation:  rect.: 0,7-1mg/kg max. 15mg; oral 0,5mg/kg (beides i.v.-Lösung)",
          FIELD3: "",
        },
        {
          FIELD1: "Morphin",
          FIELD2:
            "i.v.: 0,1mg/kg/ED;  4-6x/d; DTI: 10-100µg/kg/h;  Erwachsene: 5-10mg/ED p.o.: 0,2-0,5mg/kg/ED alle 4h",
          FIELD3: "Bei Tachyphylaxie höhere Dosen möglich",
        },
        {
          FIELD1: "Nitrofurantoin",
          FIELD2:
            "p.o.: 5mg/kg/d in  3-4ED; Erwachsene: 300mg/d in 3ED Rezidiv-HWI-Prophylaxe: 1mg/kg/d als ED.",
          FIELD3: "ab 4 Mo.; Cave G6PD",
        },
        {
          FIELD1: "Nystatin (Moronal)",
          FIELD2: "p.o.: 0,5-1ml/ED 4-6  x tgl..",
          FIELD3: "",
        },
        {
          FIELD1: "Omeprazol",
          FIELD2:
            "i.v./p.o.: 1(-3)mg/kg/d in 1-2ED. Sonde:  i.v.-Lösung per Sonde möglich. Erwachsene: selten mehr als 40mg/d in 1ED",
          FIELD3: "",
        },
        {
          FIELD1: "Ondansetron (Zofran)",
          FIELD2:
            "i.v.: 5mg/m2/ED,  3 x tgl., max. 8mg/ED  p.o.:  0,1-0,2mg/kg/ED max 3 x tgl. max 20mg",
          FIELD3:
            "Bei Gastroenteritis off-label, Aufklärung erforderlich.  Cave QT-Verlängerung!",
        },
        {
          FIELD1: "Paracetamol",
          FIELD2:
            "p.o./rectal: 15mg/kg/ED  max. 6-stdl.(<3Mo 8-12stdl), max 60 mg/kg/d;  i.v.: 15mg/kg/ED,  max. 6-stdl; <10kgKG: max. 7,5mg/kg/ED, max. 30mg/kg/d; 10-33kg: max  60mg/kg/d, max 2g/d; 33-50kg, max 60mg/kg/d, max 3g/d, >50kg max. 4g/d",
          FIELD3: 'Antidot:  ACC hochdosiert (="Fluimucil antidot")',
        },
        {
          FIELD1: "Penicillin",
          FIELD2: "siehe Benzyl-Penicillin",
          FIELD3: "",
        },
        {
          FIELD1: "Phenobarbital",
          FIELD2:
            "p.o.: 5 (-10)mg/kg/d  in 2 ED; i.v.: 10 mg/kg/ED bei KA  und als Sedierung bei Beatmung bis max. 30mg/kg am 1. Tag, 10mg/kg am 2.Tag  dann 5mg/kg/d.in 2ED",
          FIELD3: "Zielspiegel:10-40mg/l",
        },
        {
          FIELD1: "Piperacillin",
          FIELD2:
            "i.v.: Neo  150mg/kg/d; ab 1 Jahr: 200mg/kg/d in 3ED, max. 24g/d. Erwachsene: 3-4 x 2g/d.  Meningitis: 300mg/kg/d in 4 ED",
          FIELD3: "NI",
        },
        {
          FIELD1: "Piperacillin +   Tazobactam",
          FIELD2:
            "i.v.:  240(-300)mg/kg/d Piperacillin-Anteil in 3ED als KI, Onko(FiN): 300 mg/kg/d in  3ED als KI; Max. 4g Pip pro ED; bei akutem Abdomen hohe Dosis",
          FIELD3: "NI",
        },
        {
          FIELD1: "Piritramid (Dipidolor)",
          FIELD2:
            "i.v./i.m./s.c.:  (0,05-)0,1mg/kg/ED 4-6 x tgl.; DTI:  0,02-0,05mg/kg/h",
          FIELD3: "Bei Tachyphylaxie höhere Dosen möglich",
        },
        {
          FIELD1: "Prednisolon",
          FIELD2: "i.v./p.o.: 2(-5)mg/kg/d  in 3ED. Start 2mg/kg als ED",
          FIELD3: "",
        },
        {
          FIELD1: "Promethazin (Atosil)",
          FIELD2:
            "i.v./p.o.:1mg/kg/ED, max.  40gtt/ED; cave paradoxe Reaktionen",
          FIELD3: "<2  Jahre off-Label. 1 Tropfen = 1mg",
        },
        {
          FIELD1: "Salbutamol",
          FIELD2:
            "Akute pulm. Obstruktion: p.i.: 1gtt/3kg alle  4h; max. 15gtt/ED, b.Bed auch 2-stdl.  Dosieraerosol:  1Hub=0,1mg. 2-4(-6-8…) Hübe im Asthmaanfall Oral: 1,5-3gtt/kgKG/ED, max  30gtt/ED; max. 5-9gtt/kg/d",
          FIELD3:
            "Notfalls höhere Dosis möglich, Dauerinhalation  auf Intensivstation möglich.",
        },
        {
          FIELD1: "Teicoplanin",
          FIELD2:
            "ab 2.LM: 10mg/kg/ED alle 12h für 3 Gaben, dann  10mg/kg/ED alle 24h; NG: 8mg/kg/ED alle 12h für 3 Gaben, dann 8mg/kg/ED alle  24h; Endokard. + Osteom. s.  Fachinfo",
          FIELD3:
            "Keine Spiegel (kontrovers diskutiert, evtl.   sinnvoll; Grenzen siehe Fachinfo)",
        },
        {
          FIELD1: "Tobramycin",
          FIELD2:
            "i.v.: 5(-7,5)mg/kg/d  in 1ED; CF: max. 8-10mg/kg/d.,  max. 400mg/d;  Inhalation zur p.aerug.Erad. Bei CF: <10a:  2x40mg p.i., >10a 2x80(-160)mg .p.i.",
          FIELD3:
            "NI,  Spiegel Gabe 1xtäglich <= 1 mg/l. 8h-Spiegel bei CF 8h <5 mg/l",
        },
        {
          FIELD1: "Tramadol",
          FIELD2: "i.v./p.o.: 1mg/kg/ED,  max. 8mg/kg/d. DTI: 0,1-0,5mg/kg/h",
          FIELD3: "",
        },
        {
          FIELD1: "Vancomycin",
          FIELD2:
            "i.v.: 45  (-60)mg/kg/d 3ED; Meningitis: 60mg/kg/d 3ED; Neo: 1.-7-LD 15mg/kgED, dann 10mg/kg alle 12h, 7.30LT 15mg/kgED,  dann 10mg/kg alle 8h; C.diff.:40  mg/kg/d 3ED",
          FIELD3:
            "KI 60min; Zielsp. vor 3. Gabe: 5-10, Dosis  muss oft erhöht werden. Bei ZNS-Inf. Zielsp. 10-20.",
        },
        {
          FIELD1: "Vitamin K",
          FIELD2:
            "i.v./i.m./p.o.: 1mg/kg/ED,  max. 10mg/ED  Konakion(R) nur i.m., KonakionMM(R) nicht bei  Neos dafür aber i.v. zugelassen",
          FIELD3: "",
        },
      ],
      headersamb: [
        { text: "Infektion und Indikation", value: "FIELD1" },
        { text: "Antibiotikum", value: "FIELD2" },
        { text: "Alternative / Erläuterung", value: "FIELD3" },
        { text: "Dauer", value: "FIELD4" },
      ],
      tableambulant: [
        {
          FIELD1:
            "Otitis media  AB-Therapie nur bei Sgl. <6Mo od. schlechter AZ od. protrahiert >48-72h od. Grunderkrankung.",
          FIELD2:
            "Amoxicillin 50 mg/kg/d (max 3g) in 3 ED\n Primär: Analgesie + Nasentropfen.  Otorrhoe bei Paukenröhrchen: HNO, ggf. H2O2 3%, bei Foetor Ciproflox OT 7d",
          FIELD3:
            "Bei Rez. i.d. letzten 4 Wo: Amoxi/Clav 50/12,5mg/kg/d in 3 ED\n Nach Perforation: AB nur bei Otalgie, Otorrhoe-Persistenz, Fieber",
          FIELD4: "5 (-7) d",
        },
        {
          FIELD1:
            "Pertussis  Immer antibiotische Behandlung zur Reduktion der Symptome und der Ansteckungsgefahr",
          FIELD2:
            "Im 1. LM: Azithromycin 10 mg/kg in 1ED  Ab 2. LM: Clarithromycin 15mg/kgKG/d (max 1g) in 2 ED\n Nach 5d Antibiose nicht mehr ansteckend.",
          FIELD3:
            "<6 Monate stationäre Überwachung empfohlen. Chemoprophylaxe i.d. Umgebung von Sgl. / chron. krank. Kinder, siehe DGPI-Handbuch",
          FIELD4: "Azithr. 5d  Clarithr. 7d",
        },
        {
          FIELD1:
            "Pneumonie  Hinweise auf virale Genese, dann keine AB: Vorschulalter und jünger, obstruktive Symptomatik, langsamer Beginn, rel. guter AZ.",
          FIELD2:
            "Amoxicillin 50 mg/kg/d (max 3g) in 3 ED Bei V.a. Mykoplasmen: Clarithr. 15mg/kgKG/d (max 1g) in 2 ED\n Bei >8a und V.a. atypische Erreger:\n Doxycyclin d1 4mg/kgKG, max 200mg; ab d2 2mg/kgKG/d, max. 100 mg",
          FIELD3:
            "<6 Mo Amoxi/Clav, immer i.v.  <8 Wo wie NG-Sepsis  >8a und schlechte Compliance: Doxy\n Bei rez. Mikroaspirationen / pulm. Grunderkr: AmoxiClav",
          FIELD4: "5(-7)d  Myk.7-10d  Doxy 7d",
        },
        {
          FIELD1:
            "Sinusitis: Primär symptomatisch. Antibiose bei Persistenz (eitr. Rhinosinusitis >10d); schweren Symptomen (eitr. Rhinosin. + >39°C >3d +-Gesichtsschmerzen), Verschlechterung (biphasischer Verlauf)",
          FIELD2:
            "Amoxicillin 50mg/kg/d (max 3g) in 3 ED Re-eval nach 3d. Bei Nichtansprechen: Amoxi/Clav 50mg/12,5mg/kg/d in 3 ED\n Ab 9a: Doxycyclin d1 4mg/kgKG, max 200mg; ab d2 2mg/kgKG/d, max. 100 mg",
          FIELD3:
            "Bildgebung und HNO-Vorstellung bei V.a. Komplikationen:  - Schwellung periorbital / fazial  - Augenmotilitätsstörung  - Jeglichem V.a. intrakr. Kompl.!",
          FIELD4: "5(-10)d",
        },
        {
          FIELD1:
            "Tonsillitis / Pharyngitis  AB nur bei Nachweis von A-Strep. Kein GAS-ST: <3 Jahre (GAS bei Sgl Rarität), und bei H.a. Virusinfekt (Husten, Schnupfen, Konjunktivitis, Stomatitis).  Bei häufigen Rezidiven: Cefadroxil 5d (höhere Eradikationsrate)",
          FIELD2:
            "InfectoBiCicillin:  1-6 Jahre: 2mal täglich 1/2 Messlöffel, Tagesdosis 750.000 I.E.  6-12 Jahre: 2mal täglich 1 Messlöffel, Tagesdosis 1.500.000 I.E.  >12 Jahre: 2mal täglich 1-2 Messlöffel, Tagesdosis 1.500.000 - 3.000.000 I.E.",
          FIELD3:
            "McIsaac-Score ab 3 Jahren:  Je 1 Punkt für: Alter 3-14 Jahre;  Fieber; „kein Husten“; cervciale LK-Schwellung; Tonsillenschwellung/-exsudat. >= 3 Punkte: GAS-ST.  Bei Allergie: Clarithromycin 15mg/kg/d in 2 ED",
          FIELD4: "7d",
        },
        {
          FIELD1:
            "Lokale Antisepsis: Octenidin 0,1 % (Lsg/Spray, nicht in tiefe Wunden einbringen) od. Chlorhexidin-Creme od. Prontosan Gel (Polihexanid).  Panaritium, Abszess, oberflächliche Wunden, leichte Wundinfektionen, Balanitis: Antiseptische und ggf. chirurgische Therapie, in der Regel keine systemische AB-Therapie nötig.",
          FIELD2: "",
          FIELD3: "",
          FIELD4: "",
        },
        {
          FIELD1: "Abszess",
          FIELD2: "Amoxi/Clav 50mg/12,5mg/kg/d in 3 ED",
          FIELD3: "Meist primär chirurgisch",
          FIELD4: "n. Klinik 5-7d",
        },
        {
          FIELD1: "Impetigo Contagiosa / Phlegmone",
          FIELD2: "Cefadroxil 50mg/kg/d in 2 ED, Jgdl. 2x1g",
          FIELD3: "Amoxi/Clav 50/12,5mg/kg/d in 3 ED",
          FIELD4: "n. Klinik 5-7d",
        },
        {
          FIELD1: "Bisswunden",
          FIELD2: "Amoxi/Clav 50mg/12,5mg/kg/d in 3 ED",
          FIELD3: "Wundtoilette; Tetanus? Tollwut?",
          FIELD4: "5(-10)d",
        },
        {
          FIELD1:
            "„Infizierter“ Insektenstich\n keine Antibiose bei Schwellung und/oder Lymphangitis <48h nach Stich\n (=“verstärkte Lokalreaktion“)",
          FIELD2: "bei Infektion:\n Cefadroxil 50mg/kg/d in 2 ED, Jgdl 2x1g",
          FIELD3:
            "Auch bei verstärkter Lokalreaktion ohne Antibiose: immer antiseptische Umschläge und Ruhigstellung",
          FIELD4: "nach Klinik  3(-5)d",
        },
        {
          FIELD1:
            "Lymphadenitis Colli\n Antibiose nur wenn unilateral, da bilateral meist viral.",
          FIELD2:
            "Amoxi/Clav 50mg/12,5mg/kg/d in 3 ED  Ggf. bis EBV ausgeschlossen: Cefadroxil",
          FIELD3:
            "Ggf. Sono: Einschmelzung? RT-23? TSpot NTM? i.v.-Gabe bedenken.  Bei Persistenz >3Wo OP",
          FIELD4: "n. Klinik, 7d",
        },
        {
          FIELD1: "Periorbitale Cellulitits\n Cave bei Motilitätseinschränkung",
          FIELD2: "Amoxi/Clav 50mg/12,5mg/kg/d in 3 ED",
          FIELD3:
            "Meist Beginn i.v., HNO + AA;\n p.o. nur bei mildem Befund, nur Befall Oberlid, guter AZ, keine KS",
          FIELD4: "n. Klinik  5(-7)d",
        },
        {
          FIELD1: "Konjunktivitis eitrig\n <4 Wo: an Gonok. u. Chlam. denken",
          FIELD2:
            "Nur bei protrah. eitriger Sekr und konj. Rötung: Gentamicin-AT 4x/d",
          FIELD3:
            "Meist gar keine Therapie nötig. Systemisch nur bei Gon./Chlam.",
          FIELD4: "n. Klinik  4-5d",
        },
        {
          FIELD1: "Mykose der Haut (Z.n. Windelsoor)",
          FIELD2:
            "Topisch: Nystatin (candio-hermal) oder Nystatin+Zink (Multilind)",
          FIELD3: "Windelsoor: zusätzlich Nystatin p.o.: Moronal 4x1ml 10-12d",
          FIELD4: "",
        },
        {
          FIELD1: "Skabies  Bei Skabies crustosa: stationär und Derma-Konsil",
          FIELD2:
            "Ab 15kgKG: Ivermectin p.o. 0,2mg/kgED.\n <15kgKG: Permethrin 5% Creme 8-12h (Aussparung Mund und Augen)",
          FIELD3:
            "NG und Sgl.: stationär Neos Permethrin 2,5%. Allgemeine Maßnahmen beachten, Kombither. topisch/systemisch möglich.",
          FIELD4: "Rez./crustosa: Wdh nach 1 Wo",
        },
        {
          FIELD1:
            "Zystitis\n keine AB bei nur leichten Beschwerden, kein Fieber, unsicherer Diagnose",
          FIELD2:
            "Nitrofurantoin (Nifuretten 20mg)  5 mg/kg KG in 3-4 ED, entsprechen täglich 3 (-4) Tabletten (cave G6PD)",
          FIELD3:
            "Cefaclor 40mg/kg/d in 3 ED Mädchen ab 12a und >50kgKG: Fosfomycin 1x3g p.o. (ED abends)",
          FIELD4: "3 (-5)d",
        },
        {
          FIELD1: "Pyelonephritis",
          FIELD2: "Cefpodoxim 10mg/kg/d in 2 ED  (max 400mg/d)",
          FIELD3:
            "Immer intravenös bei: <3-6Mo; red. AZ, Exsikkose, Harntraktanom.; chron. Niereninsuff; transplant.  Bei erster PN: Sono zeitnah (muss nicht stationär sein)",
          FIELD4: "7(-10)d",
        },
        {
          FIELD1: "",
          FIELD2: "Oxyuriasis",
          FIELD3:
            "Pyrantel (Helmex) 10mg/kg/d p.o. 1malig ab 7. LM; bei Persistenz 3x eine ED an d1 d14 d28 + Behandlung der Angehörigen",
          FIELD4: "Mebendazol (Vermox) 1x100mg ab 2a",
        },
      ],
    };
  },
  computed: {
    withBrTags: function () {
      const doc = this.tableambulant.FIELD1;
      return doc.replace(/(\\r)*\\n/g, "<br>");
    },
  },
};
</script>
<style lang="scss">
.med-table {
  font-size: 0.8rem;

  .v-data-table table {
    border-collapse: collapse;
    margin: 1rem 0;
    display: block;
    overflow-x: auto;
  }
  .v-data-table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    color: black !important;
  }
  .v-data-table tr {
    border-top: 1px solid #dfe2e5;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  td,
  th {
    border: 1px solid #dfe2e5;
    padding: 0.4em 0.8em;
  }
  .v-data-table th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    background: white;
    position: sticky;
    top: 0;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  //td:nth-child(1)
  //    font-weight:500;
  //   color:cornflowerblue;
  //
  tr {
    background: white;
    border-top: 1px solid #dfe2e5;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
}
#myInput {
  background-image: url("/css/searchicon.png"); /* Add a search icon to input */
  background-position: 10px 12px; /* Position the search icon */
  background-repeat: no-repeat; /* Do not repeat the icon image */
  width: 100%; /* Full-width */
  font-size: 16px; /* Increase font-size */
  padding: 12px 20px 12px 40px; /* Add some padding */
  border: 1px solid #ddd; /* Add a grey border */
  margin-bottom: 12px; /* Add some space below the input */
}
.pinamedtable.v-card__title {
  background: white !important;
}
.theme--dark .pinamedtable.v-card__title {
  background: var(--background-color-secondary) !important;
}
.pinamedtable.v-data-table td {
  border-bottom: thin solid #ebebeb !important;
  border-left: thin solid #ebebeb !important;
}

.v-data-table td:nth-child(1) {
  font-weight: 500;
  color: black;
  border-left: 0px !important;
  line-height: 1.3;
  letter-spacing: -0.025em;
}
.v-data-table.alternate tr:nth-child(even) {
  background-color: #f5f5f5 !important;
  letter-spacing: -0.025em;
  color: #555;
  transition: ease-in 0.2s !important;
}
.v-data-table.alternate tr:nth-child(even) {
  letter-spacing: -0.025em;
}
.v-data-table.alternate tr:nth-child(odd) {
  letter-spacing: -0.025em;
  color: #555;
}
.v-data-table.alternate tr:hover {
  color: black !important;
  cursor: pointer;
  background-color: #fff8e1 !important;
    transition: ease-in 0.1s !important;

}

.theme--dark .v-data-table tr:nth-child(2n) {
  background-color: #f6f8fa90 !important;
}
.v-data-table td:nth-child(1) {
  border-top: 0px !important;
}
.text-block {
  white-space: pre; // or pre-line
}
.med-tabs .v-tab {
  background: white !important;
}
.med-tabs .v-tab--active {
  background: white !important;
}

.v-tabs-items {
  background: #ffffff00 !important;
}
.med-tabs .v-tab:not(.v-tab--active) {
  background: #eeeeee !important;
}

::-webkit-scrollbar {
  display: none;
}
.searchdrugs {
  margin-top: -0.5rem !important;
  font-weight: 400;
  border: 1px solid #e5e5e5;
}
.searchdrugs .v-input__icon.v-input__icon--append {
  position: relative;
  top: 10px !important;
}
.v-input .v-label {
  font-size: 0.875rem;
  color: #64748b !important;
}
</style>
