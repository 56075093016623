<template>
  <v-sheet elevation="0" flat class="mt-4 honto-shadow honto-border" style="border: 1px solid rgba(51,65,85,.15)!important; margin-top:-8px; background:margin:0!important;var(--background-color-primary-light)!important; border-radius: 8px !important;margin-top:-.75rem;">
    <v-row justify="space-around">
      <v-card flat width="400" style="border-radius:8px;">
        <v-card-text>
          <div style="font-size:1rem;opacity:.7;" class="font-weight-medium ml-7 mb-2 mt-6 lstight">
            Letzte Honto Updates
          </div>

          <v-timeline style="margin-left:-1rem;font-size:.75rem;"
            align-top
            dense
          >
            <v-timeline-item
              v-for="message in messages"
              :key="message.time"
              :color="message.color"
              small
            >
            <template v-slot:icon>
        <v-avatar size="34" class="mt-2 timelineavatar" style="object-fit: cover!important;">
          <img style="object-fit: cover;" :src="message.color">
        </v-avatar></template>
              <div style="margin-left:-1.5rem;" class="font-weight-normal">
                <div>
                  <strong>{{ message.from }}</strong> <span style="color:blue!important;" class="newsfeedicon icon-newsfeed"></span><span style="color:#0284c7;">{{ message.time }}</span>
                </div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        <span style="padding-bottom:1.5rem; font-size:1.5rem;"><center>...</center></span>  
        <br/></v-card-text>
      </v-card>
    </v-row>
  </v-sheet>
</template>

<script>
  export default {
    data: () => ({
      messages: [
        {
          from: 'CA Reichert',
          message: `Standard "Fieberkrampf" aktualisiert`,
          time: ' heute',
          color: '../img/rchrt.119a5a2c.jpg',
        },
        {
          from: 'OA Heigele',
          message: `Standard "Kopfplatzwunden" erstellt`,
          time: 'vor 3 Tagen',
          color: 'https://www.gpge-kongress.de/wp-content/uploads/2022/10/heigele.jpg',
        },
        {
          from: 'CA Enninger',
          message: 'Standard "Chronische Bauchschmerzen" aktualisiert',
          time: 'September 2022',
          color: 'https://pbs.twimg.com/profile_images/1465774356689301518/h2W3HJjL_400x400.jpg',
        },
        {
          from: 'OA Hospach',
          message: 'Standard "JIA" aktualisiert',
          time: 'August 2022',
          color: 'https://www.esanum.de/backend/media/images/2017/9/8/d132ead2-41aa-4ad6-ae45-19f76ccf3d43/d876e336.jpg',
        },
      ],
    }),
  }
</script>

<style>
.timelineavatar{
background:white;
border:2px solid white;
    outline: 1px solid rgb(211, 211, 211);
}
.icon-newsfeed:before {
  background-image: url("../img/icon-std-edit.svg");
}
.newsfeedicon:before {
  content: " ";
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  position: relative;
  margin-right: 0.2rem;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  opacity:.4;
  filter:brightness(0);
  fill:#0284c7!important;
}
</style>