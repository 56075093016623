<template>
  <div
    v-if="!!iconMap[type]"
    :class="iconMap[type].name"
    :color="iconMap[type].color"
  />
  <v-icon v-else>mdi-file</v-icon>
</template>

<script>
export default {
  props: ["type"],
  data: () => {
    return {
      iconMap: {
        [".pdf"]: { name: "pdficon", color: "red lighten-1" },
        [".docx"]: { name: "wordicon", color: "#00a2ed" },
        [".xml"]: { name: "excelicon", color: "green darken-2" },
        [".ppt"]: { name: "powerpointicon", color: "grey darken-2" },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.wordicon {
  content: " ";
  display: inline-block;
 // background-image: url("../img/word.svg");
  background-size: 20px 20px;
  height: 18px;
  width: 18px;
  position: relative;
}
.pdficon:before {
  content: "PDF ";
  display: inline-block;
  //background-image: url("../img/pdf.svg");
  background-size: 20px 20px;
  height: 18px;
  width: 18px;
  position: relative;
  margin-right:5px!important;
  font-size:.75rem;
}
.excelicon {
  content: " ";
  display: inline-block;
  background-image: url("../img/excel.svg");
  background-size: 20px 20px;
  height: 18px;
  width: 18px;
  position: relative;
}
.powerpointicon {
  content: " ";
  display: inline-block;
  background-image: url("../img/powerpoint.svg");
  background-size: 20px 20px;
  height: 18px;
  width: 18px;
  position: relative;
}
</style>
