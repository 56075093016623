<template>
  <v-container class="pa-2">
    <v-row class="mx-auto pa-2 maincontainer">
      <v-col cols="12" md="12">
        <div class="px-2 tablecontainer">
          <DataTable class="py-1 px-4" resource="standards" /></div></v-col
    ></v-row>
  </v-container>
</template>

<script>
import DataTable from "../components/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  name: "Standards",
};
</script>
