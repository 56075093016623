var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('PdfDialog',{attrs:{"path":_vm.url},on:{"close":function($event){return _vm.setUrl('')}}}),_c('v-subheader',{staticClass:"lstight standardpageheader"},[_vm._v(" "+_vm._s(_vm.resource === "standards" ? "Standardbibliothek" : "Dokumentenbibliothek")+" "),(_vm.resource === 'standards')?_c('AddStandard',{on:{"added":_vm.handleAdded}}):_vm._e(),_c('v-spacer'),_c('v-text-field',{staticClass:"searchdocs",staticStyle:{"font-weight":"400","border":"1px solid #e5e5e5 !important","font-size":".875rem","color":"#64748b!important","margin-top":"-.5rem"},attrs:{"append-icon":"mdi-magnify","label":_vm.department + ' durchsuchen',"dense":"","hide-details":"","single-line":"","rounded":"","background-color":"white","height":"44px"},on:{"input":_vm.debouncedSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.pagination)?_c('v-data-table',{staticClass:"tablerow",staticStyle:{"background":"none!important"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.standards,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [10, 20, 50] }},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.setUrl($event.url)}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"pr-1",staticStyle:{"top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-format-letter-case")]),_vm._v(_vm._s(header.text)+" ")]}},{key:"header.category",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"pr-1",staticStyle:{"top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-tag-plus-outline")]),_vm._v(_vm._s(header.text)+" ")]}},{key:"header.updatedAt",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"pr-1",staticStyle:{"top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-update")]),_vm._v(_vm._s(header.text)+" ")]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"pr-1",staticStyle:{"top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-account-circle-outline")]),_vm._v(_vm._s(header.text)+" ")]}},{key:"header.ext",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"pr-1",staticStyle:{"top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-file-hidden")]),_vm._v(_vm._s(header.text)+" ")]}},{key:"header.validUntil",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{staticClass:"pr-1",staticStyle:{"top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v(_vm._s(header.text)+" ")]}},{key:"item.validUntil",fn:function(ref){
var item = ref.item;
return [_c('ValidityChip',{attrs:{"date":item.validUntil}})]}},{key:"item.ext",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"transform":"scale(.875)"},attrs:{"small":"","dark":"","color":"blue-grey lighten-4"}},[_c('DocIcon',{attrs:{"type":item.ext}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"animate bert"},[(_vm.resource === 'standards')?_c('EditStandard',{attrs:{"standard":item},on:{"updated":_vm.handleUpdated}}):_vm._e()],1),_c('span',{staticClass:"animate bert"},[(_vm.resource === 'standards')?_c('DeleteStandard',{attrs:{"standardId":item.id},on:{"deleted":_vm.handleDeleted}}):_vm._e()],1)]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }