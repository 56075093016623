<template>
  <v-card flat class="mx-auto">
    <PdfDialog :path="url" @close="setUrl('')" />
    <v-subheader class="lstight standardpageheader">
      {{
        resource === "standards" ? "Standardbibliothek" : "Dokumentenbibliothek"
      }}
      <AddStandard v-if="resource === 'standards'" @added="handleAdded" />
      <v-spacer></v-spacer>
      <v-text-field
        style="font-weight:400; border:1px solid #e5e5e5 !important;font-size:.875rem;
    color:#64748b!important;margin-top:-.5rem;"
        class="searchdocs"
        v-model="search"
        append-icon="mdi-magnify"
        :label="department + ' durchsuchen'"
        dense
        hide-details=""
        single-line
        rounded
        background-color="white"
        height="44px"
        @input="debouncedSearch"
      ></v-text-field>
    </v-subheader>
    <v-data-table
      v-if="pagination"
      dense
      class="tablerow"
      style="background: none!important;"
      :headers="headers"
      :items="standards"
      :search="search"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
      @click:row="setUrl($event.url)"
    >
      <template v-slot:[`header.name`]="{ header }">
        <v-icon class="pr-1" style="top:-1px;" small
          >mdi-format-letter-case</v-icon
        >{{ header.text }}
      </template>
      <template v-slot:[`header.category`]="{ header }">
        <v-icon class="pr-1" style="top:-1px;" small
          >mdi-tag-plus-outline</v-icon
        >{{ header.text }}
      </template>
      <template v-slot:[`header.updatedAt`]="{ header }">
        <v-icon class="pr-1" style="top:-1px;" small>mdi-update</v-icon
        >{{ header.text }}
      </template>
      <template v-slot:[`header.actions`]="{ header }">
        <v-icon class="pr-1" style="top:-1px;" small
          >mdi-account-circle-outline</v-icon
        >{{ header.text }}
      </template>
      <template v-slot:[`header.ext`]="{ header }">
        <v-icon class="pr-1" style="top:-1px;" small>mdi-file-hidden</v-icon
        >{{ header.text }}
      </template>
      <template v-slot:[`header.validUntil`]="{ header }">
        <v-icon class="pr-1" style="top:-1px;" small
          >mdi-checkbox-marked-circle-outline</v-icon
        >{{ header.text }}
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        <ValidityChip :date="item.validUntil" />
      </template>
      <template v-slot:[`item.ext`]="{ item }">
        <v-chip style="transform: scale(.875);" small dark color="blue-grey lighten-4"><DocIcon style="" :type="item.ext" /></v-chip>
        
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <div>{{ item.id }}</div>
        <div>{{ item.id }}</div> -->
        <span class="animate bert">
        <EditStandard
          v-if="resource === 'standards'"
          :standard="item"
          @updated="handleUpdated"
        /></span>
        <span class="animate bert">
        <DeleteStandard
          v-if="resource === 'standards'"
          :standardId="item.id"
          @deleted="handleDeleted"
        /></span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { fetchResource } from "../api";
import qs from "qs";
import moment from "moment";
import PdfDialog from "./PdfDialog.vue";
import EditStandard from "./EditStandard.vue";

import DocIcon from "./DocIcon.vue";
import ValidityChip from "./ValidityChip.vue";
import { debounce } from "../utils";
import AddStandard from "./AddStandard.vue";
import DeleteStandard from "./DeleteStandard.vue";

export default {
  components: {
    PdfDialog,
    EditStandard,
    DocIcon,
    ValidityChip,
    AddStandard,
    DeleteStandard,
  },
  props: ["resource", "category"],
  data() {
    return {
      url: "",
      p: null,
      page: 1,
      loading: true,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
        mustSort: true,
        multiSort: false,
        itemsPerPage: 10,
      },
      pagination: null,
      search: "",
      standards: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    department() {
      return this.category || this.$route.path.split("/")[1].toUpperCase();
    },
    user() {
      return this.$store.state.strapi.user;
    },
    headers() {
      const headers = [
        { text: "Gültig bis", value: "validUntil", sortable: true },
        { text: "Titel", value: "name", sortable: true },
        // { text: "Klinik", value: "category", sortable: false },
        //    { text: "Erstellt", value: "firstCreated", sortable: false },
        { text: "Geändert", value: "updatedAt", sortable: true },
        {
          text: "Typ",
          align: "start",
          sortable: false,
          value: "ext",
        },
      ];
      if (this.user?.role?.type === "editor" && this.resource === "standards") {
        headers.push({
          text: "Admin",
          value: "actions",
          sortable: false,
        });
      }
      return headers;
    },
    total() {
      return this.pagination.total ?? null;
    },
    currentPage() {
      return this.pagination.page ?? null;
    },
    pageCount() {
      return this.pagination.pageCount ?? null;
    },
  },
  methods: {
    handleUpdated() {
      this.getDataFromApi();
    },
    handleDeleted() {
      this.getDataFromApi();
    },
    handleAdded() {
      this.getDataFromApi();
    },
    setUrl(url) {
      this.url = url;
    },
    debouncedSearch: debounce(function() {
      this.getDataFromApi();
    }, 300),
    async getDataFromApi() {
      this.loading = true;
      let { sortBy, sortDesc, page, itemsPerPage } = JSON.parse(
        JSON.stringify(this.options),
      );
      this.options.mustSort = true;
      if (itemsPerPage === -1) {
        this.options.itemsPerPage = this.total;
      }
      if (sortBy) {
        for (let idx = 0; idx < sortBy.length; idx++) {
          if (sortBy[idx] === "category") {
            sortBy[idx] += ".name";
          }
          sortBy[idx] += ":" + (sortDesc[idx] ? "desc" : "asc");
        }
      }
      const searchString = this.search.toLowerCase();
      const query = qs.stringify(
        {
          filters: {
            $and: [
              {
                category: {
                  name: { $containsi: this.department },
                },
              },
              {
                $or: [
                  { name: { $containsi: searchString } },
                  { keywords: { $containsi: searchString } },
                  { keywords_hidden: { $containsi: searchString } },
                ],
              },
            ],
          },
          populate: ["file", "category", "keywords", "keywords_hidden"],
          sort: sortBy,
          pagination: {
            page: page,
            pageSize: itemsPerPage,
          },
        },
        { encodeValuesOnly: true },
      );
      const standards = await fetchResource(this.resource, query);
      this.loading = false;
      this.pagination = standards.meta.pagination;
      this.standards = standards.data.map(standard => {
        let validUntil;
        if (standard.attributes.validUntil) {
          validUntil = moment(standard.attributes.validUntil).format(
            "DD.MM.YY",
          );
        }
        let updatedAt = moment(standard.attributes.updatedAt).format(
          "DD.MM.YY",
        );
        // let createdAtPlusOneYear = moment(standard.attributes.createdAt)
        //   .add(1, "Y")
        //   .format("DD.MM.YY");
        //  let firstCreated = moment(standard.attributes.createdAt)
        //   .format("DD.MM.YY");
        return {
          //   author: standard.attributes.createdBy,
          id: standard.id,
          name: standard.attributes.name,
          category: standard.attributes.category.data.attributes.name,
          ext: standard.attributes.file.data.attributes.ext,
          updatedAt,
          //  firstCreated,
          validUntil: validUntil,
          url: standard.attributes.file.data.attributes.url,
          keywords: standard.attributes.keywords.split(", "),
          keywords_hidden: standard.attributes.keywords_hidden?.split(", "),
        };
      });
    },
    getIcon(ext) {
      if (ext === ".pdf") return "pdficon";
      else if (ext === ".docx") return "wordicon";
      else if (ext === ".xml") return "excelicon";
      else if (ext === ".ppt") return "powerpointicon";
      else return "mdi-file";
    },
  },
};
</script>
<style>
.v-data-table__mobile-table-row {
  border: 1px solid #ededed;
  display: block;
}
.v-application .text-start {
  text-align: start !important;
  cursor: pointer !important;
  font-weight: 500;
}
.text-start {
  opacity: 1;
  color: #475569;
  transition: ease-in 0.1s !important;
}
.text-start:hover {
  color: black;
}
.v-subheader {
  margin: 1rem 0;
}
.tablerow tr {
  height: 38px !important;
}
.searchdocs .v-input__icon.v-input__icon--append {
  position: relative;
  top: 10px !important;
}
.v-input .v-label {
  font-size: 0.875rem;
  color: #64748b !important;
}

.tablerow td:nth-child(3) {
  font-weight: 400;
}
.tablerow td:nth-child(4) {
  font-weight: 400;
}

.tablerow td:nth-child(6) {
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    height: 55px !important;
    margin: 0;
    padding: 0.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
  }
  .tablerow td:nth-child(1) {
    display: none;
  }
  .tablerow td:nth-child(3) {
    display: none;
  }
  .tablerow td:nth-child(4) {
    display: none;
  }
  .tablerow td:nth-child(5) {
    display: none;
  }
  .tablerow td:nth-child(6) {
    display: none;
  }
}
.tablerow .v-data-table__mobile-row__header:nth-child(1) {
  display: none;
}
.tablerow .v-data-table--mobil th:nth-child(3) {
  display: none;
}
.tablerow .v-data-table--mobil th:nth-child(4) {
  display: none;
}
.tablerow .v-data-table--mobil th:nth-child(5) {
  display: none;
}
.tablerow .v-data-table--mobil th:nth-child(6) {
  display: none;
}

.standardpageheader {
  font-size: 1.6rem !important;
  font-weight: 500;
  color: black !important;
}

@media screen and (max-width: 600px) {
  .standardpageheader {
    font-size: 0.875rem !important;
  }
  .searchdocs {
    width: 150px;
  }
}
.searchdocs {
  margin-top: 4px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.07) !important;
}
.v-data-table tr:hover {
  cursor: pointer;
  border-bottom: thin solid rgba(0, 0, 0, 1) !important;
  transition: ease-in-out 0.1s !important;
}
.bert {
  opacity: .2;

}
.v-data-table tr:hover .bert {
  opacity: 1;
  -webkit-transition: opacity 0.1s ease-in-out;
    -moz-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out;
}
.v-input--hide-details > .v-input__control > .v-input__slot {
border:none!important;}
</style>
