
<template>
  <div class="card-grid">
     <div v-if="loading">
      <v-skeleton-loader
        class="mx-0"
        max-width="1fr"
        type="card, list-item-three-line"
      ></v-skeleton-loader>
    </div>
    <div v-if="loading">
      <v-skeleton-loader
        class="mx-0"
        max-width="1fr"
        type="card, list-item-three-line"
      ></v-skeleton-loader>
    </div>
    <div v-if="loading">
      <v-skeleton-loader
        class="mx-0"
        max-width="1fr"
        type="card, list-item-three-line"
      ></v-skeleton-loader>
    </div>
  <v-row v-if="cards && cards.data" class="my-0">
    <v-col
      style="height: auto; display: flex"
      v-for="card in cards.data"
      :key="card.id"
      cols="12"
      md="4"
      sm="6"
    >
      <v-card
      style="border-radius:6px 6px 6px 6px!important;"
        tile
        class="dashboard-card dark-border honto-border honto-shadow"
        min-width="10px"
      >
        <v-img style="border-radius:4px 4px 0px 0px!important;"
          :src="`${card.attributes.image.data.attributes.url}`"
          gradient="to top, rgba(0,0,0,.5), rgba(255,255,255,.001)"
          class="white--text align-end"
          height="160px"
        >
          <v-card-title class="px-6 lstight" style="font-size: 1.4rem; font-weight:500;">
            {{ card.attributes.name }}
          </v-card-title>
        </v-img>
        <v-card-text
          class="pa-6 pt-5 pb-0"
          style="
            color: black !important;
            white-space: pre-wrap;
            font-size: 0.875rem !important;
            font-weight:500;
            color:#111;
            letter-spacing:-0.009rem;
            background-image: url('data:image/svg+xml,%3csvg width='1144px' height='945px' viewBox='0 0 1144 945' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3cg id='Design-Desktop-DE' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.200000003'%3e %3cg id='Wall-Desktop' transform='translate(-117.000000, -64.000000)'%3e %3ccircle id='Oval' stroke='%23CEF0E8' stroke-width='80' cx='1097' cy='260' r='124'%3e%3c/circle%3e %3cpolyline id='Path' stroke='%23F0D8ED' stroke-width='80' stroke-linecap='round' stroke-linejoin='round' transform='translate(951.000000, 873.000000) rotate(-357.000000) translate(-951.000000, -873.000000) ' points='800 961 951 785 1102 961'%3e%3c/polyline%3e %3cpath d='M117.060179,372.823706 C115.449186,455.485192 146.183032,538.659898 209.261715,601.73827 C332.276704,724.75391 531.723274,724.75391 654.73827,601.73827 C777.75391,478.723274 777.75391,279.276726 654.73827,156.261719 C593.230738,94.7538612 512.615264,63.9999542 431.999811,64 L431.999833,218.534579 C473.549715,216.881484 515.639931,231.915985 547.361895,263.638105 C607.663653,323.939574 607.663653,421.707482 547.361895,482.008951 C487.060426,542.310732 389.292496,542.310732 328.991027,482.008951 C298.84017,451.85825 283.76473,412.340978 283.764686,372.823706 L117.060179,372.823706 Z' id='logo' fill='%23CCE1F0'%3e%3c/path%3e %3c/g%3e %3c/g%3e %3c/svg%3e') !important;
          "
        >
          <div
            target="_blank"
            rel="noopener noreferrer"
            v-html="markedText(card.attributes.content)"
          ></div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { marked } from "marked";
import qs from "qs";
import { fetchResource } from "../api";

export default {
  name: "Cards",

  data() {
    return {
      cards: null,
      loading: true,
      error: false,
    };
  },
  methods: {
    markedText(text) {
      return marked.parse(text);
    },
  },
  async created() {
    const query = qs.stringify({
      populate: "image",
    });
    this.cards = await fetchResource("cards", query);
    if (!this.cards.error) {
      this.loading = false;
    } else {
      this.error = true;
    }
  },
};
</script>
<style>
.card-grid {
  padding: 1.2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: 1.5rem!important;
  justify-content: center;
}
.dashboard-card.v-card {
  background: white !important;
  color: black !important;

}
.dashboard-card.v-card:hover {
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
}
</style>
