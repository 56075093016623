<template>
  <div class="med-search in-left honto-shadow">
    <div class="input-wrapper">
      <div
        style="color:cornflowerblue;padding-right:5rem!important;"
        class="pre-search"
      >
        <v-icon style="color:#333;top:1px; padding-left:4px;padding-right:4px;"
          >mdi-magnify
        </v-icon>
        <div>
          <div
            style="transform: rotate(-90deg) scale(.35); top:4px; margin-left:0rem;"
            class="arrow"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div v-if="!isFocused && !searchString" class="placeholder">
          Suchen mit <span class=" ml-3 keycap">F</span>
        </div>
      </div>
      <input
        v-model="searchString"
        ref="searchInput"
        @focus="onFocus"
        @blur="onBlur"
      />
      <v-btn
        v-if="searchString"
        class="mr-4"
        icon
        text
        @click="searchString = ''"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div v-if="searchString && !noResults" class="list">
      <div
        v-for="standard in cachedSearchResults"
        :key="standard.id"
        class="list-item lstight"
        @click="selectedStandard = standard"
      >
        <div style="color:#1e293b;" class="hoversuggestionitem">
          <span>{{ standard.attributes.name }}</span>
          <p style="display: inline" class="keywordstyle"></p>
          <p class="keywordstyle"
            v-if="standard.attributes.keywords"
            style="
                    display: inline;
                    margin-left:.5rem;
                    font-weight: 400;
                    font-size: 0.8rem;
                    color: #94a3b8 !important;
                  "
          >
            Enthält Informationen zu:
            <span class="keywordstyle"
              color="primary"
              style="
                      display: inline;
                      font-size: 0.8rem;
                      font-weight: 500;
                      color: #64748b !important;
                    "
              >{{ standard.attributes.keywords }}</span
            >
          </p>
          <v-chip
            small
            class="ml-2 pl-4 pr-4"
            color="rgb(243 244 246)"
            text-color="rgb(100 116 139)"
            outlined
            style="font-weight:400!important;border-color:rgb(203 213 225) !important;"
          >
            <v-icon
              small
              left
              outlined
              class="mr-1"
              style="font-weight:400!important;"
              :class="
                getIcon(standard.attributes.category.data.attributes.name)
              "
            >
            </v-icon>
            {{ standard.attributes.category.data.attributes.name }}
          </v-chip>
        </div>
      </div>
      <div
        v-if="hasMore"
        class="list-item blue lighten-5"
        @click="!loadingSearch ? loadMore() : undefined"
      >
        <div class="d-flex justify-center text-center" style="gap: 0.5rem">
          <div>Weitere Ergebnisse</div>
          <v-progress-circular
            v-if="loadingSearch"
            indeterminate
            size="20"
            style="color:rgb(44 112 166) !important"
          ></v-progress-circular>
          <v-icon v-else>mdi-chevron-double-down</v-icon>
        </div>
      </div>
      <div class="loaded-all" v-else-if="cachedSearchResults">
        Alle Ergebnisse geladen
      </div>
    </div>
    <div v-else-if="searchString" class="no-results">
      <v-icon class="pr-1" style="top:-2px; color:#e5e5e5;">mdi-cancel</v-icon
      >Keine Ergebnisse
    </div>
    <PdfDialog
      :path="pdfPath"
      :different-department="differentDepartment"
      @close="selectedStandard = null"
    />
    <v-dialog
      v-if="$vuetify.breakpoint.xsOnly"
      v-model="fullscreenSearch"
      fullscreen
    >
      <div class="d-flex">
        <label for="searchInput2" class="flex-grow-1 ">
          <input
            ref="searchInput2"
            v-model="searchString"
            id="searchInput2"
            class="searchInput rq-form-element"
            autocomplete="off"
            type="text"
            style="
                box-shadow: none;
                border: none;
                border-radius: 0px !important;
              "
          />
          <i id="searchinputss2"></i
          ><span
            class="i.search fas fa-search searchicon-align text"
            style="display: inline-block; position: absolute"
          ></span>
        </label>
        <div class="white">
          <v-btn
            icon
            x-large
            class="my-2"
            style="position: absolute; top: -2px; right: 6px"
          >
            <v-icon @click="fullscreenSearch = false">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <ul
        role="listbox"
        id="suggestions"
        class="suggestions"
        style="
            visibility: visible;
            background: #b2dfdb;
            max-height: 100vh;
            height: 100vh;
            box-shadow: none;
            top: 0;
          "
      >
        <li v-if="loadingSearch" class="d-flex">
          <v-progress-linear height="4" indeterminate></v-progress-linear>
        </li>
        <template v-if="searchResults">
          <li
            v-for="standard in searchResults.data"
            :key="standard.id"
            style="padding: 1.5rem; color: #004d40;    letter-spacing: -.025em;"
          >
            <v-row>
              <v-col
                cols="auto"
                style="cursor: pointer"
                @click="selectedStandard = standard"
              >
                <a style="display: inline; witdh: 100%; font-weight: 600">
                  <span style="padding-right: 10px">
                    {{ standard.attributes.name }}</span
                  >
                  <div style="display: inline-block; font-weight: 400">
                    <v-chip small outlined color="#f3f3f3">
                      <v-icon
                        size="16"
                        color="white"
                        style="padding-right: 6px"
                      >
                        mdi-file-outline </v-icon
                      >{{
                        " " + standard.attributes.category.data.attributes.name
                      }}</v-chip
                    >
                  </div>
                  <div
                    v-if="standard.attributes.keywords"
                    class="mb-0"
                    style="font-weight: 400; font-size: 0.8rem; color: #00897b"
                  >
                    Enthält Informationen zu:
                    <span
                      style="
                          display: inline;
                          font-size: 0.8rem;
                          font-weight: 500;
                          color: #00897b;
                        "
                    >
                      <v-icon class="mr-2 pr-2 nav-honto navicon-pina"></v-icon>
                      {{ standard.attributes.keywords }}</span
                    >
                  </div>
                </a>
              </v-col>
            </v-row>
          </li>
        </template>
      </ul>
    </v-dialog>
  </div>
</template>

<script>
import PdfDialog from "./PdfDialog.vue";
import mousetrap from "mousetrap";
import { debounce, search } from "../utils";

export default {
  name: "MedSearch",
  components: { PdfDialog },
  computed: {
    pagination() {
      return this.searchResults?.meta?.pagination ?? null;
    },
    noResults() {
      if (this.loadingSearch) {
        return false;
      }
      return (
        this.searchString?.length > 0 &&
        this.cachedSearchResults &&
        this.cachedSearchResults.length === 0
      );
    },
    hasMore() {
      if (this.searchResults == null) {
        return false;
      }
      const { page, pageCount } = this.searchResults.meta.pagination;
      return page < pageCount;
    },
    pdfPath() {
      return this.selectedStandard != null
        ? this.selectedStandard.attributes.file.data.attributes.url
        : "";
    },
    differentDepartment() {
      return !!(
        this.selectedStandard?.attributes.category.data.attributes.name !==
        "PINA"
      );
    },
  },
  data() {
    return {
      isFocused: false,
      suggestions: [],
      fullscreenSearch: false,
      loadingSearch: false,
      loadingError: false,
      searchString: "",
      searchResults: null,
      cachedSearchResults: null,
      page: 1,
      selectedStandard: null,
    };
  },
  mounted() {
    mousetrap.bind("f", () => this.focusSearchBar());
    this.$nextTick(() => this.$refs.searchInput.focus());
  },
  watch: {
    fullscreenSearch: "onFullscreenSearchChange",
    searchString: "searchStandards",
  },
  methods: {
    searchStandards: debounce(async function(searchString, oldSearchString) {
      if (!searchString) {
        this.page = 1;
        this.searchResults = null;
        this.cachedSearchResults = null;
        this.loadingSearch = false;
        this.loadingError = false;
        return;
      }
      this.loadingSearch = true;
      this.loadingError = false;
      const loadingMore = oldSearchString === searchString;
      if (!loadingMore) {
        this.page = 1;
      }
      try {
        this.searchResults = await search(
          searchString,
          this.page,
          this.searchIn,
        );
        if (this.cachedSearchResults == null || !loadingMore) {
          this.cachedSearchResults = this.searchResults.data;
        } else {
          this.cachedSearchResults = [
            ...this.cachedSearchResults,
            ...this.searchResults.data,
          ];
        }
      } catch (e) {
        console.log(e);
        this.searchResults = null;
        this.cachedSearchResults = null;
        this.loadingSearch = false;
        this.loadingError = false;
      }
      this.loadingSearch = false;
    }, 300),
    loadMore() {
      this.page = this.pagination.page + 1;
      this.searchStandards(this.searchString, this.searchString);
    },
    onFocus(e) {
      this.isFocused = true;
    },
    onBlur(e) {
      this.isFocused = false;
    },
    focusSearchBar() {
      setTimeout(() => this.$refs.searchInput.focus());
    },
    onFullscreenSearchChange(newVal) {
      if (newVal) {
        setTimeout(() => this.$refs.searchInput2.focus());
      } else {
        this.searchString = "";
        this.searchResults = null;
      }
    },
    getFileName(entry) {
      return entry.path.replace(/^.*[\\/]/, "").replace(".pdf", "");
    },
    getIcon(ext) {
      if (ext === "PINA") return "pinaicon";
      else if (ext === "Neonatologie") return "neoicon";
      else if (ext === "Neo-auf-MC01") return "neoicon";
      else if (ext === "Gastroenterologie") return "paedicon";
      else if (ext === "Nephro-Endokrinologie") return "paedicon";
      else if (ext === "Rheumatologie") return "paedicon";
      else if (ext === "Organisatorisches-SOP") return "paedicon";
      else if (ext === "Infektiologie") return "paedicon";
      else if (ext === "Intensiv") return "intensivicon";
      else if (ext === "Onkologie") return "oncology";
      else if (ext === "Onkologie-TUZ") return "oncology";
      else return "mdi-file";
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.med-search {
  outline: 1px solid rgb(219, 219, 219) !important;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  &:hover {
    cursor: pointer !important;
    outline: 1px solid rgb(51, 148, 232) !important;
    transition: opacity 0.1s ease-in-out, outline 0.1s ease-in-out;
  }

  > .input-wrapper {
    display: flex;
    height: 3.5rem;
    align-items: center;
    > .pre-search {
      display: flex;
      align-items: center;
      background: #ededed;
      position: relative;
      height: 100%;
      padding-inline: 1rem;
      border-right: 1px solid #dfdfdf !important;
      > .placeholder {

        pointer-events: none;
        display: flex;
        align-items: center;
        position: absolute;
        height: 3rem;
        white-space: nowrap;
        left: calc(100% + 1rem);
        color: #737981;
        font-size: 0.875rem;
              
      }
    }
    > input {
      height: 100%;
      padding: 0;
      margin: 0;
      width: 100%;
      padding-block: 1rem;
      padding-inline: 1rem;
      font-weight: 500 !important;

      &:focus {
        outline: none;
      }
    }
  }
  > .no-results {
    padding-block: 0.9rem;
    padding-inline: 1.4rem;
    text-align: center;
    color: lightgrey;
    border-top: #e5e5e5 1px solid !important;
  }
  > .list {
    max-height: 295px;
    overflow-y: scroll;
    font-weight: 500;
    .loaded-all {
      padding-block: 0.9rem;
      padding-inline: 1.4rem;
      text-align: center;
      color: lightgrey;

      border-top: #e5e5e5 1px solid !important;
    }
    .list-item {
      cursor: pointer;
      &:hover {
        background: rgba(33, 150, 150, 0.044) !important;
        border-left: 3px solid #2196f3;
        border-radius: 0px !important;
        transition: bottom 0.8s ease 0.4s;
        transition: ease-in-out 0.1s;
      }
      &:nth-child(odd) {
        background: #fbfbfb;
      }
      &:first-child {
        border-top: #e5e5e5 1px solid !important;
      }

      border-top: #e5e5e5 1px solid !important;
      padding-block: 0.9rem;
      padding-inline: 1.4rem;
    }
  }
}
.pinaicon {
  content: "  ";
  display: inline-block;
  background-image: url("../img/nav-pina.svg");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  position: relative;
 filter: invert(36%) sepia(89%) saturate(4749%) hue-rotate(197deg)
    brightness(97%) contrast(80%);  
}
.neoicon {
  content: "  ";
  display: inline-block;
  background-image: url("../img/nav-neo.svg");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  position: relative;
  filter: invert(36%) sepia(89%) saturate(4749%) hue-rotate(197deg)
    brightness(97%) contrast(80%);
}
.paedicon {
  content: "  ";
  display: inline-block;
  background-image: url("../img/nav-p2.svg");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  position: relative;
  filter: invert(36%) sepia(89%) saturate(4749%) hue-rotate(197deg)
    brightness(97%) contrast(80%);
}
.intensivicon {
  content: "  ";
  display: inline-block;
  background-image: url("../img/nav-p3.svg");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  position: relative;
  filter: invert(36%) sepia(89%) saturate(4749%) hue-rotate(197deg)
    brightness(97%) contrast(80%);
}
.oncology {
  content: "  ";
  display: inline-block;
  background-image: url("../img/nav-p5.svg");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  position: relative;
  filter: invert(36%) sepia(89%) saturate(4749%) hue-rotate(197deg)
    brightness(97%) contrast(80%);
}
.hoversuggestionitem:hover {
  //background:rgba(227, 227, 227, 0.383);
  //padding:.4rem .6rem;
}
.v-chip:hover {
  cursor: pointer;
}
.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.arrow span {
  display: block;
  width: 24px;
  height: 24px;
  border-bottom: 5px solid #333;
  border-right: 5px solid #333;
  transform: rotate(45deg);
  margin: -6px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
.keycap {
    align-items: center;
    background: linear-gradient(-225deg,#d5dbe4,#f8f8f8);
    border-radius: 3px;
    box-shadow: inset 0 -2px 0 0 #cdcde6,inset 0 0 1px 1px #fff,0 1px 2px 1px rgba(30,35,90,.4);
    color: #666;
    display: flex;
    height: 28px;
    font-weight:500;
    justify-content: center;
    margin-right: 0.4em;
    padding-bottom: 0px;
    position: relative;
    top: -1px;
    width: 30px;
}
@media screen and (max-width:600px) {
 .keywordstyle {
display:none!important;}}
</style>
