<template>
  <div>
    <v-divider></v-divider>
    <div class="mt-4" style="margin-left: -0.5rem">
      <template v-if="quicklinks">
        <h3 style="font-size:1.1rem; font-weight:400;" class="ml-3">Abteilungsspezifische Links & Tools</h3>
        <template v-for="quicklink in quicklinks.data">
          
          <v-chip
            outlined
            :key="quicklink.id"
            class="ma-2 mt-0"
            append-icon="mdi-account"
            style="font-weight:500;"
            
            target="_blank"
            :href="quicklink.attributes.link"
          ><v-icon small color=teal class="mr-1">
      mdi-earth</v-icon>
            <span>{{ quicklink.attributes.name }}</span>
          </v-chip>
        </template>
      </template>
      <!--
      <v-chip
        class="ma-2"
        outlined
        label
        href="https://www.uptodate.com/contents/search"
        target="_blank"
      >
        <v-icon left> mdi-alpha-u-box </v-icon>
        UpToDate
      </v-chip>
      <v-chip
        class="ma-2"
        label
        outlined
        href="https://www.embryotox.de/"
        target="_blank"
      >
        <v-icon left> mdi-baby-carriage </v-icon>
        Embryotox
      </v-chip>
      <v-chip
        class="ma-2"
        outlined
        label
        href="https://www.pedz.de/"
        target="_blank"
      >
        <v-icon left> mdi-calculator-variant </v-icon>
        Ped(Z)-Rechner
      </v-chip>-->
    </div>
  </div>
</template>
<script>
import { fetchResource } from "../api";
import { marked } from "marked";
export default {
  data() {
    return {
      quicklinks: null,
    };
  },
  name: "quicklink",
  methods: {
    markedText(text) {
      return marked.parse(text);
    },
  },
  async created() {
    this.quicklinks = await fetchResource("quicklinks");
  },
};
</script>
<style lang="scss" scoped>
.v-chip {
  color: var(--highlight-color) !important;
}
.theme--dark.v-chip {
  color: var(--accent-color) !important;
}
</style>
