<template>
  <v-dialog
    style="border-radius: 0px !important"
    v-model="dialog"
    max-width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        text
        icon
        rounded
        class="lstight"
        style="text-transform:none!important; height:34px;font-weight:400;font-size:0.8rem!important;"
        elevation="0"
        v-bind="attrs"
        v-on="on"
        ><div class="actionicon icon-trash"></div>
      </v-btn>
    </template>
    <v-card tile elevation="0" class="pa-6 honto-border bg-card">
      <v-card-title color="error" style="position:relative!important;color:#ff5252;">Standard löschen</v-card-title>
      <v-card-text style="position:relative!important;color:#666;">Sind Sie sicher, dass Sie den Standard löschen wollen? {{ standardName }}</v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" class="px-3 pr-4 mr-2" outlined style="border-color:#cbd5e1;">Abbrechen</v-btn>
        <v-btn elevation="0" class="pr-4" color="error" @click.prevent="deleteStandard"><v-icon class="pr-1">mdi-delete-forever</v-icon>Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { CMS_URL } from "../api";
import FetchService, { FetchMethod } from "../api/FetchService";

export default {
  name: "DeleteStandard",
  props: ["standardId"],
  data: vm => ({
    valid: false,
    dialog: false,
  }),
  methods: {
    async deleteStandard(e) {
      e.preventDefault();
      const response = await FetchService.fetchApi(
        FetchMethod.delete,
        CMS_URL + "standards/" + this.standardId,
      );

      this.$emit("deleted", response.data);
      this.dialog = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style lang="scss">
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__selections {
  padding-top: 2rem !important;
  padding-bottom: 1rem !important;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  font-weight: 400 !important;
  font-size: 1rem;
}
.v-chip--select:hover {
  background: #ff634780 !important;
}
.v-chip--select.v-chip--active {
  background: #ee725d !important;
  color: white;
}
.stdupload .v-select__selection {
  font-weight: 500 !important;
  text-transform: uppercase;
  color: rgb(92, 146, 255);
  letter-spacing: 0.05rem;
  font-size: 0.8rem;
}
.v-autocomplete.v-input > .v-input__control > .v-input__slot:hover {
  background: #f5f5f5 !important;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
  font-weight: 500;
}
.std-img {
  background: url("https://images.unsplash.com/photo-1643228995868-bf698f67d053?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80");
  background-size: cover;
  background-position: center bottom;
  height: 180px;
  color: white !important;
}
.std-2 {
  background: url("https://images.unsplash.com/photo-1654976487477-bdbab53994c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1636&q=80");
  background-size: cover;
  background-position: center;
  height: 180px;
  color: white !important;
}
.radio-button {
  padding: 1rem;
  border: 1px solid #ebebeb;
}

////////////////////////////// Radio Component //////////////////////////////////@at-root

.plans {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 48.5%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e1e2e7;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #252f42;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked + .plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.plans .plan input[type="radio"]:checked + .plan-content {
  border: 0px solid cornflowerblue;
  border-left: 4px solid cornflowerblue;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.1s;
  -o-transition: ease-in 0.1;
  transition: ease-in 0.1s;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 40px;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}
.plans .plan input[type="radio"]:checked + .plan-content img {
  filter: invert(40%) sepia(30%) saturate(7083%) hue-rotate(205deg)
    brightness(92%) contrast(92%);
}
/*
.bg-card  {
      -webkit-box-pack: center !important;
    justify-content: center !important;
    background-repeat: no-repeat !important;
    background-position: center top !important;
    background-image: url("data:image/svg+xml,%3csvg width='1144px' height='945px' viewBox='0 0 1144 945' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3cg id='Design-Desktop-DE' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.200000003'%3e %3cg id='Wall-Desktop' transform='translate(-117.000000, -64.000000)'%3e %3ccircle id='Oval' stroke='%23CEF0E8' stroke-width='80' cx='1097' cy='260' r='124'%3e%3c/circle%3e %3cpolyline id='Path' stroke='%23F0D8ED' stroke-width='80' stroke-linecap='round' stroke-linejoin='round' transform='translate(951.000000, 873.000000) rotate(-357.000000) translate(-951.000000, -873.000000) ' points='800 961 951 785 1102 961'%3e%3c/polyline%3e %3cpath d='M117.060179,372.823706 C115.449186,455.485192 146.183032,538.659898 209.261715,601.73827 C332.276704,724.75391 531.723274,724.75391 654.73827,601.73827 C777.75391,478.723274 777.75391,279.276726 654.73827,156.261719 C593.230738,94.7538612 512.615264,63.9999542 431.999811,64 L431.999833,218.534579 C473.549715,216.881484 515.639931,231.915985 547.361895,263.638105 C607.663653,323.939574 607.663653,421.707482 547.361895,482.008951 C487.060426,542.310732 389.292496,542.310732 328.991027,482.008951 C298.84017,451.85825 283.76473,412.340978 283.764686,372.823706 L117.060179,372.823706 Z' id='logo' fill='%23CCE1F0'%3e%3c/path%3e %3c/g%3e %3c/g%3e %3c/svg%3e") !important;
    background-size: cover !important;
} */
.actionicon:before {
  content: " ";
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  position: relative;
  margin-right: 0rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.icon-trash:before {
  background-image: url("../img/icon-trash.svg");
  left:-0.5px!important;
}

</style>
