<template>
  <div>
    <v-app-bar height="170px" dark flat>
      <template v-slot:img="{}">
        <div
          :style="{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }"
        >
          <img src="../img/c19bg.jpg" style="width: 100%" />
          <div
            :style="{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              backgroundImage:
                'linear-gradient(to right, rgb(30, 143, 143), rgba(20, 31, 29, 0))',
            }"
          ></div>
        </div>
      </template>
      <v-app-bar-title
        class="mx-auto lstight pt-1"
        style="
          position: absolute;
          width: 1000px;
          height: 150px;
          top: 115px !important;
          margin-left: 0;
          font-weight: 500;
          margin-top: -6rem;
          max-width: 1400px !important;
        "
      >
        PINA Notaufnahme
        <span class="subpage">
          <div class="glassbadge px-6 lstight">
            <span
              class="headericon"
              :class="
                $route.name === 'Dashboard'
                  ? 'icon-dashboard'
                  : $route.name === 'Standards'
                  ? 'icon-standards'
                  : $route.name === 'Medikamente'
                  ? 'icon-medikamente'
                  : $route.name === 'Dokumente'
                  ? 'icon-docs'
                  : $route.name === 'Onboarding'
                  ? 'icon-onboarding'
                  : ''
              "
              >{{ $route.name }}
            </span>
          </div></span
        >
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <template v-slot:extension>
        <v-tabs
          dark
          show-arrows
          class="breadcrumbs mx-auto"
          style="margin-top: -11rem; max-width: 1400px; left: 0"
        >
          <v-tab to="/pina" style="font-size: 1rem !important">
            Dashboard
          </v-tab>
          <v-divider inset vertical />
          <v-tab to="/pina/standards" style="font-size: 1rem !important">
            Standards
          </v-tab>
          <v-divider inset vertical />
          <v-tab to="/pina/drugs" style="font-size: 1rem !important">
            Medikamente
          </v-tab>
          <v-divider inset vertical />
          <v-tab to="/pina/documents" style="font-size: 1rem !important">
            Dokumente
          </v-tab>
          <v-divider inset vertical />
          <v-tab to="/pina/onboarding" style="font-size: 1rem !important">
            Onboarding
          </v-tab>

          <v-spacer></v-spacer>

          <v-tab
            v-if="user"
            style="min-width: 60px !important"
            class="mx-7 pa-2 align-center"
            icon-text
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-badge avatar color="red" light bordered overlap dot>
              <v-avatar style="border: 1px solid #e5e5e5" size="32px">
                <img style="object-fit:cover;" :src="userImage" />
              </v-avatar>
            </v-badge>
            <span class="mx-2 white--text text-h7"
              >Willkommen, {{ user.username }}</span
            >
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <transition mode="out-in">
      <router-view :key="$route.path"></router-view>
    </transition>
  </div>
</template>

<script>
import { me } from "../api";

export default {
  name: "HeaderTabs",
  props: ["title", "image"],
  computed: {
    user() {
      return this.$store.state.strapi.user;
    },
    userImage() {
      return this.user?.userimage?.formats?.thumbnail?.url;
    },
  },
};
</script>

<style lang="scss">
.headericon:before {
  content: " ";
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
  position: relative;
  left: -0.3rem;
  margin-right: 0.3rem;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  filter: brightness(0) invert(1);
}
.icon-dashboard:before {
  background-image: url("../img/header-icon-dashboard.svg");
}
.icon-standards:before {
  background-image: url("../img/header-icon-standards.svg");
}
.icon-docs:before {
  background-image: url("../img/header-icon-docs.svg");
}
.icon-medikamente:before {
  background-image: url("../img/header-icon-medikamente.svg");
}
.icon-onboarding:before {
  background-image: url("../img/header-icon-onboarding.svg");
}

.v-tab {
  text-transform: capitalize !important;
  letter-spacing: -0.025em !important;
  font-weight: 500 !important;
}

.v-app-bar-title__content {
  width: 100% !important;
  font-size: 1.8rem !important;
  margin-left: 1rem;
}
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
  visibility: hidden;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 20px !important;
  padding: 0 16px;
}
@media only screen and (max-width: 800px) {
  .v-app-bar-title__content {
    font-size: 1.4rem;
    width: 100% !important;
  }
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.v-toolbar__image {
  border-radius: 0px !important;
}
.v-toolbar {
  contain: layout;
  display: block;
  flex: 1 1 auto;
  max-width: 100%;
  transition: 0s cubic-bezier(0.4, 0, 0.2, 1) transform,
    0.2s cubic-bezier(0.4, 0, 0.2, 1) background-color,
    0.2s cubic-bezier(0.4, 0, 0.2, 1) left,
    0.2s cubic-bezier(0.4, 0, 0.2, 1) right,
    280ms cubic-bezier(0.4, 0, 0.2, 1) box-shadow,
    0.25s cubic-bezier(0.4, 0, 0.2, 1) max-width,
    0.25s cubic-bezier(0.4, 0, 0.2, 1) width;
  box-shadow: none !important;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100%;
    padding: 2rem;
    padding-top: 2rem;
  }
}

.breadcrumbs {
  // backdrop-filter: blur(13px);
  margin-left: 0.02rem !important;
  border-top: 1px solid rgba(255, 255, 255, 0.32);
  border-color: rgba(255, 255, 255, 0.476) !important;
}
.breadcrumbs .v-tab {
  line-height: 1.25rem;
  font-weight: 500;
  color: #f5f5f5 !important;
}
.breadcrumbs .theme--light.v-divider {
  border-color: rgba(255, 255, 255, 0.687) !important;
}

.v-image__image--preload {
  filter: blur(0px);
}
.v-tabs-slider-wrapper {
  height: 2px !important;
  color: #f5f5f5 !important;
  border-radius: 10px;
}

@media (max-width: 1264px) {
  .admin-btn {
    right: 0.9rem !important;
  }
}
.admin-btn {
  position: absolute;
  right: 2rem;
  height: 48px;
  background: primary;
}
#pina-header {
  .v-image__image.v-image__image--preload.v-image__image--cover {
    background-image: linear-gradient(
        to right,
        rgb(30, 143, 143),
        rgba(20, 31, 29, 0)
      ),
      url("../img/c19bg.jpg") !important;
    background-position: top top !important;
  }
}
.v-image__image.v-image__image--preload.v-image__image--cover {
  height: 500px !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  align-items: center;
  display: flex;
  position: relative;
  max-width: 1400px !important;
  margin: 0 auto !important;
  max-height: 200px !important;
}

.v-badge--bordered .v-badge__badge::after {
  border-width: 1px !important;
  transform: scale(1.2) !important;
}

.glassbadge {
  padding: 7px;
  position: relative;
  top: -2.5px !important;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 300 !important;
  margin-left: 1rem;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 23px 0 rgba(31, 38, 135, 0.17);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.08);
}
</style>
