<template>
  <div class=" Sidebar-rechts" style="margin: -0.25rem !important;
            border-radius: 8px;
            margin-bottom: -1.5rem;
            
            height: fit-content !important;
            ;">
    <div v-if="loading" class="pb-4">
      <v-skeleton-loader
        class="mx-auto"
        style="margin-top: -8px;"
        type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </div>
    <PdfDialog :path="pdfPath" @close="pdfPath = ''" />
    <template v-if="accordions">
      <div class=" pt-3 pb-2 honto-shadow honto-border" style="border: 1px solid rgba(51,65,85,.15)!important; margin-top:-8px; background:var(--background-color-primary-light)!important; border-radius: 8px !important;margin-top:-.75rem;">
      <v-expansion-panels 
        v-for="accordion in accordions.data"
        :key="'accordion-' + accordion.id"
        accordion
        flat
        class="mb-6 pb-1 lstight"
      >
        <v-subheader
          class="sidebar-header lstight"
          style="
            font-size: 1rem;
            color: rgb(147 147 147) !important;
            word-break: break-all !important;
            padding-left: 1.4rem !important;
            font-weight: 500 !important;
            padding: 1.25rem 1.4rem 1.25rem 1.4rem;
            margin-bottom: 0.4rem !important;
            overflow: hidden !important;
            white-space: nowrap;
          "
        >
          <span class="sidebar-stationsarbeit" :class="accordion.attributes.title === 'Stationsarbeit' ? 'sidebar-icon-stationsarbeit' : accordion.attributes.title === 'Hilfsmittel & Grundlagen' ? 'sidebar-icon-hilfsmittel' : accordion.attributes.title === 'E-Learning' ? 'sidebar-icon-elearning' : '' "> </span>
          {{ accordion.attributes.title }}</v-subheader
        >
        <v-divider
          style="
            height: 5px !important;
            position: relative;
            left: -1.5rem;
            margin: -0.6rem 3rem;
            padding: 0;
            color: red;
            border-width: 1px !important;
            border-color: var(--border-color-light) !important;
          "
        ></v-divider>
        <v-expansion-panel
          v-for="lists in accordion.attributes.accordionLists"
          :key="'lists-' + lists.id"
        >
          <v-expansion-panel-header
            style="margin-top: -2px; overflow: hidden !important"
          >
            {{ lists.title }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div
              v-for="item in lists.accordionListItem"
              :key="'item-' + item.id"
              class="sb-arrow"
            >
              <a v-if="item.link" :href="item.link" target="_blank">
                {{ item.title }}
                <v-icon color="grey" small>mdi-open-in-new</v-icon>
              </a>
              <span v-else @click="handleDocumentClick(item)">
                {{ item.title }}
              </span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </div>

    </template>

    <!--<Events /> -->
    <!--  <Documents /> -->
    <Newsfeed />
  </div>
  
</template>

<script>
import Newsfeed from "../components/Newsfeed.vue";
import PdfDialog from "../components/PdfDialog.vue";
import { fetchResource } from "../api";
//import Documents from "../components/Documents.vue";

export default {
  name: "Sidebar",
  components: {
    PdfDialog, Newsfeed
    //  Documents,
  },
  data() {
    return {
      elements: "",
      expand: false,
      currentPage: 0,
      pageCount: 0,
      pdfPath: "",
      accordions: null,
      selectedAccordion: null,
      loading: true,
    };
  },
  methods: {
    handleDocumentClick(item) {
      if (item.document != null && item.document.data != null) {
        this.pdfPath = item.document.data.attributes.file.data.attributes.url;
      }
      if (item.standard != null && item.standard.data != null) {
        this.pdfPath = item.standard.data.attributes.file.data.attributes.url;
      }
    },
  },
  async created() {
    const accordionsRaw = await fetchResource("accordions", {
      populate: [
        "accordionLists",
        "accordionLists.accordionListItem",
        "accordionLists.accordionListItem.document",
        "accordionLists.accordionListItem.document.file",
        "accordionLists.accordionListItem.standard",
        "accordionLists.accordionListItem.standard.file",
      ],
    });
    this.accordions = accordionsRaw;
    if (!this.accordions.error) {
      this.loading = false;
    } else {
      this.accordions = true;
    }
  },
};
</script>
<style lang="scss">
.v-icon.notranslate.mdi.mdi-alert-circle-outline {
  color: teal;
  font-size: 22px;
}

#tabs-textbausteine {
  .v-tabs-slider-wrapper {
    width: 4px !important;
    color: black !important;
  }
}
#textbausteine {
  .v-application--wrap {
    min-height: auto !important;
  }
}
.v-expansion-panel-header--active {
  color: #262626;
}
.theme--dark .v-expansion-panel-header--active {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
  font-weight: 600;
}
.sb-arrow {
  cursor: pointer;
  white-space: pre-wrap;
  line-height: 1.7;
}

.sb-arrow::before {
  content: " ";
  background-image: url("../img/list-arrow.svg");
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  font-weight: 700;
  position: relative;
  left: 0.5rem;
  top: -3px;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  margin-right: 0.8rem;
}

.sb-arrow-after {
  line-height: 1.4;
  font-weight: 400;
}
.sb-arrow-after:hover {
  transform: scale(1.01);
  transition: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition-duration: 0.1s;
}
.sb-arrow-after::after {
  content: "";
  background-image: url("../img/list-arrow.svg");
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  font-weight: 700;
  position: relative;
  left: 0.5rem;
  top: -3px;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.v-expansion-panel-header {
  font-size: 0.9rem;
  min-height: 14px !important;
}

.sidebar-header {
  color: #262626 !important;
  font-size: 1rem;
  word-break: break-all !important;
  white-space: no-wrap;
  text-overflow: ellipsis !important;
  font-weight: 500 !important;
  width: 100%;
  text-align: left;
  margin: 0 !important;
}

.v-expansion-panel-header {
  padding: 0rem 24px !important;
  font-size: 0.895rem !important;
  font-weight: 400;
}
.v-expansion-panel {
  padding-top: 0.875rem !important;
}
.v-expansion-panels {
  border-radius: 0px !important;
  font-size: 0.875rem !important;
}
.v-expansion-panels {
}
.v-expansion-panel-header {
}
.v-expansion-panels--flat > .v-expansion-panel::before {
}
.theme--dark.v-expansion-panels {
}
.honto-border {
  border-radius: 4px !important;
}
.v-expansion-panel-content__wrap {
  padding: 14px 10px 0px 22px !important;
}
.v-expansion-panel-header--active {
  font-weight: 600 !important;
}

//.sidebar-header:before {
//  content: " ";
//    background-image: url(../img/iconsinfo.svg);
//    width:20px;
//    height:20px;
//    background-size: 20px;
//    margin-right:10px;
//}
.sidebar-stationsarbeit:before {
  content: " ";
  background-size: 22px 22px;
  height: 22px;
  width: 22px;
  position: relative;
  top:-2px;
  margin-right:0.5rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  filter: brightness(0) opacity(0.4);
}
.sidebar-icon-stationsarbeit:before {
    background-image: url("../img/sidebar-icon-stationsarbeit.svg");
}
.sidebar-icon-hilfsmittel:before {
    background-image: url("../img/sidebar-icon-hilfsmittel.svg");
}
.sidebar-icon-elearning:before {
    background-image: url("../img/sidebar-icon-elearning.svg");
    
}
</style>
