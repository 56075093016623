import { stringify } from "qs";
import { fetchStandards } from "../api";

export async function search(searchString, page, searchIn) {
  const lowerSearchString = searchString.trim().toLowerCase();
  if (lowerSearchString === "") {
    return null;
  }

  let queryParams = stringify(
    {
      populate: ["category", "file"],
      filters: {
        $or: [
          {
            name: {
              $containsi: lowerSearchString,
            },
          },
          {
            keywords: {
              $containsi: lowerSearchString,
            },
          },
          {
            keywords_hidden: {
              $containsi: lowerSearchString,
            },
          },
          {
            category: {
              name: {
                $containsi: lowerSearchString,
              },
            },
          },
        ],
      },
      pagination: {
        page: page || 1,
        pageSize: 25,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const standards = await fetchStandards(queryParams);
  if (searchIn && searchIn.length > 0) {
    return standards.data.filter(standard =>
      searchIn.includes(standard.attributes.category.data.attributes.name),
    );
  } else {
    return standards;
  }
}

export function debounce(fn, delay) {
  var timeoutID = null;
  return function() {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}
