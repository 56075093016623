var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"height":"170px","dark":"","flat":""},scopedSlots:_vm._u([{key:"img",fn:function(ref){return [_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../img/c19bg.jpg")}}),_c('div',{style:({
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundImage:
              'linear-gradient(to right, rgb(30, 143, 143), rgba(20, 31, 29, 0))',
          })})])]}},{key:"extension",fn:function(){return [_c('v-tabs',{staticClass:"breadcrumbs mx-auto",staticStyle:{"margin-top":"-11rem","max-width":"1400px","left":"0"},attrs:{"dark":"","show-arrows":""}},[_c('v-tab',{staticStyle:{"font-size":"1rem !important"},attrs:{"to":"/pina"}},[_vm._v(" Dashboard ")]),_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-tab',{staticStyle:{"font-size":"1rem !important"},attrs:{"to":"/pina/standards"}},[_vm._v(" Standards ")]),_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-tab',{staticStyle:{"font-size":"1rem !important"},attrs:{"to":"/pina/drugs"}},[_vm._v(" Medikamente ")]),_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-tab',{staticStyle:{"font-size":"1rem !important"},attrs:{"to":"/pina/documents"}},[_vm._v(" Dokumente ")]),_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-tab',{staticStyle:{"font-size":"1rem !important"},attrs:{"to":"/pina/onboarding"}},[_vm._v(" Onboarding ")]),_c('v-spacer'),(_vm.user)?_c('v-tab',{staticClass:"mx-7 pa-2 align-center",staticStyle:{"min-width":"60px !important"},attrs:{"icon-text":"","target":"_blank","rel":"noopener noreferrer"}},[_c('v-badge',{attrs:{"avatar":"","color":"red","light":"","bordered":"","overlap":"","dot":""}},[_c('v-avatar',{staticStyle:{"border":"1px solid #e5e5e5"},attrs:{"size":"32px"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.userImage}})])],1),_c('span',{staticClass:"mx-2 white--text text-h7"},[_vm._v("Willkommen, "+_vm._s(_vm.user.username))])],1):_vm._e()],1)]},proxy:true}])},[_c('v-app-bar-title',{staticClass:"mx-auto lstight pt-1",staticStyle:{"position":"absolute","width":"1000px","height":"150px","top":"115px !important","margin-left":"0","font-weight":"500","margin-top":"-6rem","max-width":"1400px !important"}},[_vm._v(" PINA Notaufnahme "),_c('span',{staticClass:"subpage"},[_c('div',{staticClass:"glassbadge px-6 lstight"},[_c('span',{staticClass:"headericon",class:_vm.$route.name === 'Dashboard'
                ? 'icon-dashboard'
                : _vm.$route.name === 'Standards'
                ? 'icon-standards'
                : _vm.$route.name === 'Medikamente'
                ? 'icon-medikamente'
                : _vm.$route.name === 'Dokumente'
                ? 'icon-docs'
                : _vm.$route.name === 'Onboarding'
                ? 'icon-onboarding'
                : ''},[_vm._v(_vm._s(_vm.$route.name)+" ")])])])]),_c('v-spacer')],1),_c('transition',{attrs:{"mode":"out-in"}},[_c('router-view',{key:_vm.$route.path})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }