<template>
  <v-dialog
    v-model="show"
    width="80%"
    transition="dialog-bottom-transition"
    @keydown.esc="close"
    @click:outside="close"
  >
    <v-btn @click.stop="close" elevation="2" fab
      ><v-icon color="red" style="color: tomato !important"
        >mdi-close</v-icon
      ></v-btn
    >
    <v-card class="content">
      <!--  <div v-if="!differentDepartment">
        <v-alert tile type="error" class="alert"
          >Dieser Standard gehört nicht zur Auswahl der PINA Standards
        </v-alert>
      </div>-->
      <div>
        <object :data="path" class="pdf-object"></object>
        <!--  in <obect  :style="{ 'padding-bottom': !differentDepartment ? '40px' : 0 }" -->
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PdfDialog",
  props: ["path"],
  computed: {
    show() {
      return this.path !== "";
    },
  },
  data: () => ({
    transition: "slide-y-reverse-transition",
  }),
  methods: {
    close() {
      this.$emit("close", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  margin: 0;
  font-weight: bold;
}
.content {
  overflow: hidden;
  max-height: 90vh;
  height: 90vh;
}
.pdf-object {
  width: 100%;
  height: 90vh;
  max-height: 90vh;
}
.v-btn {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  z-index: 10000000;
}
#toolbar {
  align-items: center;
  background-color: rgb(0 146 255) !important;
  color: white;
  display: flex;
  height: var(--viewer-pdf-toolbar-height);
  padding: 0 16px;
}
</style>
