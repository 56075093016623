<template>
  <div>
    <div v-if="loading" class="pb-4">
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </div>
    <template v-if="alerts">
      <template v-for="alert in alerts">
        <v-alert
          v-if="alert.active"
          :key="alert.id"
          style="border-radius: 6px"
          class="mb-7 mt-0 alert-component honto-shadow"
          prominent
          text
          :type="alertType(alert.color)"
        >
          <v-row align="center">
            <v-col class="grow">
              <div style="white-space: pre-wrap">{{ alert.text }}</div>
            </v-col>
            <v-col v-if="user.role.type === 'editor'" class="shrink">
              <EditAlert :alert="alert" @updated="handleUpdated" />
            </v-col>
          </v-row>
        </v-alert>
        <v-sheet v-else :key="alert">
        <v-alert style="border-radius: 6px;outline:none; border:dashed 1px #c1c1c1;color:#a3a3a3;"
          class="mb-7 mt-0 ers py-5 shrink" v-if="user.role.type === 'editor'"
          >
          <v-col>
            <v-row 
> <span style="position:relative;top:3px;font-size: .9rem!important;">Fügen Sie einen Hinweis für Ihr Team hinzu </span>
          <EditAlert class="pulse" style="position:absolute; margin: auto; right:.75rem; top:14px; display:inline;" :alert="alert" @updated="handleUpdated" />
            </v-row> </v-col></v-alert></v-sheet>
      </template>
    </template>
  </div>
</template>
<style lang="scss">
.v-alert {
  outline: #6363632c 1px solid;
}
.v-alert__content {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
@media only screen and (max-width: 800px) {
  .v-alert__content {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    font-weight: 500 !important;
  }
}
/** Fixing too big background circle around icon */
.v-alert--prominent .v-alert__icon:after {
  transform: scale(1);
}
.alert-component a {
  font-weight: 500 !important;
  filter: brightness(0.6);
}
.alert-component a:hover {
  font-weight: 600 !important;
  text-decoration: underline !important;
}
@media screen and (max-width: 600px) {
  .v-alert p {
    font-size: 0.775rem !important;
  }
  .v-alert--prominent .v-alert__icon:after {
    transform: scale(0.9);
  }
  .v-alert--prominent .v-alert__icon {
    margin: 0;
    padding: 0;
    transform: scale(0.8);
  }
  .ers
   {
    
}
}
</style>
<script>
import { marked } from "marked";
import { fetchResource } from "../api";
import EditAlert from "./EditAlert.vue";
export default {
  components: { EditAlert },
  data() {
    return {
      alerts: null,
      loading: true,
    };
  },
  name: "alert",
  computed: {
    user() {
      return this.$store.state.strapi.user;
    },
  },
  methods: {
    handleUpdated(data) {
      this.getDataFromApi();
    },
    async getDataFromApi() {
      const response = await fetchResource("alerts");
      this.alerts = response.data.map(a => ({ id: a.id, ...a.attributes }));
      if (!this.alerts.error) {
        this.loading = false;
      } else {
        this.error = true;
      }
    },
    markedText(text) {
      return marked.parse(text);
    },
    alertType(color) {
      if (color === "rot") {
        return "error";
      } else if (color === "gelb") {
        return "warning";
      } else if (color === "grün") {
        return "success";
      } else {
        return "info";
      }
    },
  },
  async created() {
    this.getDataFromApi();
  },
};
</script>
