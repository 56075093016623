<template>

  <v-sheet style="overflow:hidden!important;">
      <v-row
        class="mx-auto pt-4 maincontainer"
        style="max-width:1400px!important;"
      >
        <v-col cols="12" md="9">
          <div
            class="pa-6 pinabg"
          >
            <Alert />
            <MedSearchNew />
            <Cards />
            <NewsLarge />
            <br />
            <QuickLinks />
          </div>
        </v-col>

        <v-col cols="12" md="3" class="pa-4">
          <div
            class="py-3"
            style="margin:-.25rem !important;"
          >
            <Sidebar style="margin-bottom:-1.5rem!important;" />
          </div>
        </v-col>
      </v-row>
  </v-sheet>
</template>

<script>
var docWidth = document.documentElement.offsetWidth;

[].forEach.call(document.querySelectorAll("*"), function(el) {
  if (el.offsetWidth > docWidth) {
    console.log(el);
  }
});

//import HeaderTabs from "../components/HeaderTabs.vue";
import MedSearchNew from "../components/MedSearchNew.vue";
import Alert from "../components/Alert.vue";
import Cards from "../components/Cards.vue";
import Sidebar from "../components/Sidebar.vue";
import QuickLinks from "../components/QuickLinks.vue";
import NewsLarge from "../components/NewsLarge.vue";
// import Footer from "../components/Footer.vue";
//import EventsAlt from "../components/EventsAlt.vue";
//import Documents from "../components/Documents.vue";

export default {
  components: {
    MedSearchNew,
    // HeaderTabs,
    NewsLarge,
    Cards,
    Alert,
    // Documents
    QuickLinks,
    Sidebar,
    // Footer
  },

  name: "pina",
  data() {
    return {
      elements: "",

      expand: false,
      currentPage: 0,
      pageCount: 0,
      pdfPath: "",
    };
  },
}; 
</script>
<style lang="scss">
.v-main__wrap {
  width: 100%;
  overflow-x: hidden;
}
.pinabg {
   z-index:100!important; border-radius:8px;background:var(--background-color-primary-light) !important;border:0px solid #d7d7d7;box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}
@media screen and (max-width:600px) {
.pinabg {
  margin:0 -1.25rem!important;
}
}
</style>
