<template>
  <v-dialog
    style="border-radius: 0px !important"
    v-model="dialog"
    max-width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#039be5"
        dark
        class="honto-shadow lstight ml-10"
        style="
          text-transform: none;
          font-size: 0.8rem !important;
          border-color: #e1e1e1;
        "
        elevation="0"
        v-bind="attrs"
        v-on="on"
        ><div
          class="headericon icon-stdadd"
          style="
            margin-right: -0.35rem;
            position: relative;
            top: 1px;
            filter: brightness(1);
            scale: 0.9;
          "
        ></div>
        Standard hinzufügen
      </v-btn>
    </template>
    <v-card tile elevation="0" class="pa-6 honto-border bg-card">
      <v-img
        gradient="to right bottom, #000000AA, #00000000"
        class="std-add-img px-6"
        style="border-radius: 8px 8px 0px 0px !important"
      >
        <v-card-title
          style="font-size: 1.8rem; font-weight: 400 !important"
          class="mt-6 pa-0 font-weight-regular justify-space-between"
        >
          <span>Neuen Standard hinzufügen</span>
          <v-row class="ml-2" justify="start" align="start"></v-row>
          <v-chip
            close
            color="white"
            text-color="error "
            @click="dialog = false"
            @click:close="dialog = false"
          >
            Schließen
          </v-chip>
        </v-card-title>
        <v-card-subtitle
          style="font-size: 1rem; width: 80% !important"
          class="mt-2 px-0"
        >
          Fügen Sie einen neuen Standard ihrer Bibliothek hinzu.
        </v-card-subtitle>
      </v-img>
      <v-form v-model="valid" @submit.prevent="updateStandard">
        <v-row
          class="mt-4 py-4"
          style="height: 100px !important; border: red !important"
          align="start"
        >
          <v-col class="mb-0 pb-0">
            <v-text-field
              class="actionicon nameicon"
              color="primary"
              name="name"
              label="Name des Standards"
              v-model="payload.name"
              background-color="#e2e8f080"
              solo
              flat
            ></v-text-field></v-col
          ><v-col>
            <v-file-input
              v-model="payload.file"
              name="file"
              label="Datei hinzufügen"
              background-color="#e2e8f080"
              solo
              flat
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0">
          <v-col class="mb-0 pb-0">
            <v-select
              class="stdupload actionicon tagicon"
              v-model="payload.category"
              label="Zugehörige Klinik auswählen"
              name="category"
              readonly
              background-color="#e2e8f080"
              solo
              flat
              style="box-shadow: none !important"
              elevation="0"
              :items="category"
            >
              <v-chip></v-chip>
            </v-select>
          </v-col>
          <v-col>
            <v-menu
              ref="menu1"
              v-model="datePickerMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(payload.validUntil)"
                  label="Gültig bis"
                  name="validUntil"
                  background-color="#e2e8f080"
                  solo
                  flat
                  v-bind="attrs"
                  v-on="on"
                  class="actionicon calicon"
                />
              </template>
              <v-date-picker
                v-model="payload.validUntil"
                no-title
                @input="datePickerMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-divider style="margin-top: -1rem" class="mb-3"></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col class="pa-0">
            <v-container fluid class="mx-0 px-0">
              <v-combobox
                class="mx-0 actionicon visiblekeywords combichips"
                name="keywords"
                v-model="payload.keywords"
                filled
                hide-selected
                label="In Suchmaske angezeigte Stichpunkte (maximal 3)"
                multiple
                background-color="#e2e8f080"
                persistent-hint
                chips
                deletable-chips
              >
              </v-combobox>
              <v-combobox
                class="mx-0 actionicon hiddenkeywords combichips"
                v-model="payload.keywords_hidden"
                name="keywords_hidden"
                filled
                outline-color="red"
                background-color="#e2e8f080"
                hint="Diese Begriffe können als Suchbegriffe verwendet werden, werden aber in der für die Endnutzer angezeigten Suchmaske nicht angezeigt."
                hide-selected
                label="Fügen Sie Stichworte & Synonyme für die Suche hinzu"
                multiple
                chips
                deletable-chips
              >
              </v-combobox></v-container></v-col
        ></v-row>

        <v-divider class="mb-8"></v-divider>

        <v-card-actions class="ma-0 pa-0">
          <v-spacer></v-spacer>
          <v-btn
            color="dark"
            class="ml-8 px-6 py-6 pr-9"
            dark
            type="submit"
            elevation="0"
          >
            <v-icon left dark> mdi-plus </v-icon>
            Standard hinzufügen
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import { CMS_URL } from "../api";
import FetchService, { FetchMethod } from "../api/FetchService";
import { getCookie } from "../utils/CookieHelper";

export default {
  name: "AddStandard",
  data: (vm) => ({
    valid: false,
    dialog: false,
    payload: {
      name: "",
      file: null,
      category: "PINA",
      keywords: [],
      keywords_hidden: [],
      validUntil: null,
    },
    category: ["PINA"], //, "Neonatologie", "Intensivmedizin", "Onkologie"],
    datePickerMenu: false,
  }),
  computed: {
    user() {
      return this.$store.state.strapi.user;
    },

    currentTitle() {
      switch (this.step) {
        case 1:
          return "Standard Anpassen";
        case 2:
          return "Standard Hinzufügen";
        default:
          return "Account created";
      }
    },
  },
  watch: {
    ["payload.validUntil"](val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    async updateStandard(e) {
      e.preventDefault();
      const form = new FormData();
      form.append("files.file", this.payload.file);
      form.append(
        "data",
        JSON.stringify({
          name: this.payload.name,
          validUntil: this.payload.validUntil,
          category: 33,
          keywords: this.payload.keywords.join(", "),
          keywords_hidden: this.payload.keywords_hidden.join(", "),
        }),
      );

      const response = await FetchService.fetchApi(
        FetchMethod.post,
        CMS_URL + "standards",
        form,
        {
          Authorization: "Bearer " + getCookie("jwt"),
        },
      );

      this.$emit("added", response.data);
      this.dialog = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style lang="scss">
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__selections {
  padding-top: 2rem !important;
  padding-bottom: 1rem !important;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  font-weight: 400 !important;
  font-size: 1rem;
}
.v-chip--select:hover {
  background: #ff634780 !important;
}
.v-chip--select.v-chip--active {
  background: #ee725d !important;
  color: white;
}
.stdupload .v-select__selection {
  font-weight: 500 !important;
  text-transform: uppercase;
  color: rgb(92, 146, 255);
  letter-spacing: 0.05rem;
  font-size: 0.8rem;
}
.v-autocomplete.v-input > .v-input__control > .v-input__slot:hover {
  background: #f5f5f5 !important;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
  font-weight: 500;
}
.std-add-img {
  background: url("https://images.unsplash.com/photo-1633837018163-3d3f09dec30d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80");
  background-size: cover;
  background-position: center;
  height: 180px;
  color: white !important;
}

.radio-button {
  padding: 1rem;
  border: 1px solid #ebebeb;
}

.icon-stdadd:before {
  background-image: url("../img/icon-stdadd.svg");
}
.combichips .v-chip--select.v-chip {
  border: 1px solid rgb(194, 194, 194) !important;
}
.combichips.v-autocomplete.v-input > .v-input__control > .v-input__slot:hover {
  background: #ffffff40 !important;
}
.v-input.v-text-field > .v-input__control > .v-input__slot:hover {
  background: #ffffff40 !important;
}
</style>
