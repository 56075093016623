<template>
  <v-chip
    style="border:1px solid #00000020!important;height:28px;border-radius:30px;"
    class="ma-1 pa-1 pr-3"
    :color="getColor(date)"
    :text-color="getColorChip(date)"
    borde="red"
    small
    ><v-icon class="mr-1" style="font-size:20px;" :color="getTextColor(date)" small>{{
      getSymbol(date)
    }}</v-icon>
    {{ date }}
  </v-chip>
</template>

<script>
import moment from "moment";

const validityStatusMap = {
  valid: "valid",
  updateNeeded: "updateNeeded",
  deprecated: "deprecated",
};
export default {
  props: ["date"],
  data() {
    return {};
  },
  methods: {
    getValidity(validity) {
      let today = moment();
      let validUntil = moment(validity, "DD.MM.YY");
      let diff = validUntil.diff(today, "days");

      let validityStatus = validityStatusMap.valid;

      if (diff <= 90) {
        validityStatus = validityStatusMap.updateNeeded;
      }
      if (diff <= 0) {
        validityStatus = validityStatusMap.deprecated;
      }

      return validityStatus;
    },
    getColor(date) {
      const validityStatus = this.getValidity(date);
      let validtyColor = "green lighten-3";
      if (validityStatus === validityStatusMap.deprecated) {
        validtyColor = "red lighten-3";
      }
      if (validityStatus === validityStatusMap.updateNeeded) {
        validtyColor = "amber lighten-3";
      }
      return validtyColor;
    },
    getColorChip(date) {
      const validityStatus = this.getValidity(date);
      let validtyTextColor = "green darken-4";
      if (validityStatus === validityStatusMap.deprecated) {
        validtyTextColor = "red darken-4";
      }
      if (validityStatus === validityStatusMap.updateNeeded) {
        validtyTextColor = "orange darken-4";
      }
      return validtyTextColor;
    },
    getTextColor(date) {
      const validityStatus = this.getValidity(date);
      let validtyTextColor = "green darken-1";
      if (validityStatus === validityStatusMap.deprecated) {
        validtyTextColor = "red darken-2";
      }
      if (validityStatus === validityStatusMap.updateNeeded) {
        validtyTextColor = "amber darken-2";
      }
      return validtyTextColor;
    },
    getSymbol(date) {
      const validityStatus = this.getValidity(date);
      let validtySymbol = "mdi-checkbox-marked-circle";
      if (validityStatus === validityStatusMap.deprecated) {
        validtySymbol = "mdi-close-circle";
      }
      if (validityStatus === validityStatusMap.updateNeeded) {
        validtySymbol = "mdi-alert-circle";
      }
      return validtySymbol;
    },
  },
};
</script>

<style>
.theme--light .v-input:not(.v-input--checkbox) {
  border: 0px solid var(--border-color) !important;
}
.std-error {
  content: " ";
  display: inline-block;
  /* Hex: #FF3D00 */
  filter: invert(49%) sepia(76%) saturate(7231%) hue-rotate(346deg)
    brightness(87%) contrast(88%);
  background-image: url("../img/std-error.svg");
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  position: relative;
  opacity: 1;
  margin-right: 6px !important;
}
.std-warning {
  content: " ";
  /* Hex: ##FFEA00 */
  filter: invert(50%) sepia(93%) saturate(921%) hue-rotate(350deg)
    brightness(98%) contrast(96%);
  display: inline-block;
  background-image: url("../img/std-warning.svg");
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  position: relative;
  opacity: 0.8;
  margin-right: 6px !important;
  font-size:22px!important;

}
.std-checked {
  content: " ";
  /* Hex: #1DE9B6 */
  filter: invert(42%) sepia(13%) saturate(2187%) hue-rotate(73deg)
    brightness(104%) contrast(83%);
  background-image: url("../img/std-checked.svg");
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  position: relative;
  opacity: 0.8;
  margin-right: 6px !important;
}
</style>
