<template>
  <v-row>
    <v-dialog
      style="border-radius: 0px !important"
      v-model="dialog"
      max-width="900"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          rounded
          class="tim mr-2 pt-1"
          text
          color="black"
          v-bind="attrs"
          v-on="on"
        >
          <div
            class="alerticon icon-edit-square"
            style="
              font-size: 0.6rem;
              color: #039be5;
              letter-spacing: 0.9px !important;
            "
          >
            Ändern
          </div>
        </v-btn>
      </template>
      <v-card
        tile
        elevation="0"
        style="position: relative"
        class="pa-6 honto-border bg-card bg-pattern"
      >
        <v-img
          gradient="to right bottom, #0c4a6e, #00000000,#00000000"
          class="std-img px-6"
          style="border-radius: 8px 8px 0 0"
        >
          <v-card-title
            style="font-size: 1.8rem; font-weight: 400 !important"
            class="mt-6 pa-0 font-weight-regular justify-space-between"
          >
            <span>Hinweis aktualisieren</span>
            <v-row class="ml-2" justify="start" align="start"></v-row>
            <v-chip
              close
              color="white"
              text-color="error "
              @click="dialog = false"
              @click:close="dialog = false"
            >
              Schließen
            </v-chip>
          </v-card-title>
          <v-card-subtitle style="width: 80% !important" class="mt-2 px-0">
            Administratoren können Hinweise hier erstellen und editieren.<br />
          </v-card-subtitle>
        </v-img>
        <v-form v-model="valid" @submit.prevent="updateAlert">
          <v-row class="mt-4 py-4" style="border: red !important" align="start">
            <v-col class="mb-0 pb-0">
              <v-textarea
                v-model="payload.text"
                max-width="100%"
                color="primary"
                rows="3"
                class="alerticon nameiconalert"
                auto-grow
                name="name"
                label="Hinweistext"
                background-color="#e2e8f090"
                filled
            /></v-col>
          </v-row>

          <v-row class="ma-0 pa-0 mb-4">
            <v-col class="ma-0 pa-0">
              <v-radio-group
                class="alerticon coloricon"
                row
                v-model="payload.color"
              >
                <v-radio color="success" label="Grün" value="grün"></v-radio>
                <v-radio color="primary" label="Blau" value="blau"></v-radio>
                <v-radio label="Gelb" value="gelb" color="warning"></v-radio>
                <v-radio label="Rot" value="rot" color="error"></v-radio>
              </v-radio-group>
            </v-col>
            <v-divider vertical style="border: 1px solid #e1e1e1" />

            <v-col class="pl-10 pa-0">
              <v-switch
                prepend-icon="mdi-check-circle"
                label="Hinweis aktiv"
                v-model="payload.active"
              />
            </v-col>
          </v-row>
          <v-divider class="mb-8"></v-divider>

          <v-card-actions class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-btn
              color="dark"
              class="ml-8 px-6 py-6 pr-9"
              dark
              type="submit"
              elevation="0"
            >
              <v-icon left dark> mdi-plus </v-icon>
              Hinweis Aktualisieren
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { CMS_URL } from "../api";
import FetchService, { FetchMethod } from "../api/FetchService";

export default {
  props: ["alert"],
  data: (vm) => ({
    valid: false,
    dialog: false,
    payload: {
      ...vm.alert,
    },
    category: ["PINA"], //, "Neonatologie", "Intensivmedizin", "Onkologie"],
    datePickerMenu: false,
  }),
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "alert Anpassen";
        case 2:
          return "alert Hinzufügen";
        default:
          return "Account created";
      }
    },
  },
  watch: {
    ["payload.validUntil"](val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    async updateAlert(e) {
      e.preventDefault();
      const response = await FetchService.fetchApi(
        FetchMethod.put,
        CMS_URL + "alerts/" + this.payload.id,
        {
          data: {
            text: this.payload.text.trim(),
            color: this.payload.color,
            active: this.payload.active,
          },
        },
      );
      this.$emit("updated", response.data);
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
.v-autocomplete.v-input > .v-input__control > .v-input__slot:hover {
  background: #f5f5f5 !important;
}

.std-img {
  background: url("https://images.unsplash.com/photo-1664265694638-e858db3fd16b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
  background-size: cover;
  background-position: bottom;
  height: 180px;
  color: white !important;
}
.icon-edit-square:before {
  background-image: url("../img/icon-edit-square.svg");
}
.alerticon:before {
  content: " ";
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  position: relative;
  margin-right: 0.2rem;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.v-btn:focus::before {
  opacity: 0.05 !important;
}

.coloricon:before {
  background-image: url("../img/icon-edit-alert-color.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  margin-top: 2px;
  opacity: 0.6;
}
.nameiconalert:before {
  background-image: url("../img/icon-edit-std-text.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  margin-top: 16px;
  opacity: 0.6;
}
.activeicon:before {
  background-image: url("../img/icon-edit-alert-active.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  margin-top: 2px;
  opacity: 0.6;
}
.v-text-field > .v-input__control > .v-input__slot:hover {
  background: #ffffff50 !important;
}
</style>
