<template>
  <div>
    <v-app-bar-nav-icon
      @click="drawer = true"
      style="top: 3.5rem !important"
      class="d-flex d-sm-none menubutton"
    >
    </v-app-bar-nav-icon>
    <v-navigation-drawer
      width="226"
      v-model="drawer"
      app
      expand-on-hover
      :mini-variant.sync="mini"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.xs"
      overlay-color="rgba(58, 137, 153, 1)"
      overlay-opacity=".8"
    >
      <v-list-item class="mb-15 p-0 mt-7 honto-logo" justify-center>
        <router-link
          to="/"
          style="display: inline-block; transform: none !important;"
        >
          <v-list-item-avatar
            style="display: inline-block; margin-left: -0.6rem; transition: none"
          >
            <img src="../img/logo-only.svg" />
            <!-- <v-img
              class="app-logo"
              style="display: inline-block;"
              src="../img/logo-only.svg"
            ></v-img> -->
          </v-list-item-avatar>
          <v-subheader
            update:mini-variant
            style="
            position: absolute;
              color: teal;
              left:60px;
              overflow: hidden !important;
              padding: 0;
              height: auto;
              white-space: nowrap !important;
              margin-top: -8px;
              transform: none !important;
              font-size: 14px;
              margin-bottom: 20px;
            "
            ><span
              ><span style="font-weight: 600">Klinikum Stuttgart</span
              ><br /><span
                style="line-height: 0.4rem; margin-top: -14px !important,left: 60px;"
                >Olgahospital</span
              ></span
            ></v-subheader
          >
          <v-list-item-title
            class="app-title"
            style="
              font-size: 1.5rem !important;
              
              position: absolute;
              left: 60px;
              top: 14px;
              margin-right: 32px;
              margin-left: 0px;
              font-weight: 600;
              margin-bototm:2rem;
            "
            >HONTO</v-list-item-title
          >
          <!-- Check that the SDK client is not currently loading before accessing is methods -->
        </router-link>
        <!--<v-btn
          style="position:absolute;top:10px;left:170px; box-shadow:none;padding:0 1.5rem;"
          @click.stop="mini = !mini"
        >
          <v-img
            style="position:absolute; left:-7px; max-width:14px;margin-top:2px;opacity:1; "
            src="../img/arrow-lef.svg"
          ></v-img>
        </v-btn> -->
      </v-list-item>

      <!-- MENU NUR ZEIGEN WENN: v-if="$auth.isAuthenticated"  IN V-LIST -->
      <v-list class="pa-0">
        <v-divider></v-divider>
        <v-list-item-group class="pa-2">
          <v-list-item class="navigationlinks"
            v-for="item in routePINA"
            :key="item.text"
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon :class="item.icon" style="margin-right: 1rem"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title light v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--
          <v-list-item style="margin-bottom:-1.2rem;" class="pb-0"
            v-for="item in routeNeo"
            :key="item.text"
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon class="nav-honto navicon-neo" v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title light v-text="item.text"></v-list-item-title>
            
            </v-list-item-content>
            
          </v-list-item>
          <v-list-item style="margin-bottom:-1.2rem;" class="pb-0"
            v-for="item in routeP3"
            :key="item.text"
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon class="nav-honto navicon-p3" v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title light v-text="item.text"></v-list-item-title>
            
            </v-list-item-content>
            
          </v-list-item>
         -->
        </v-list-item-group>
        <v-divider></v-divider>

        <v-list-item-group class="pa-2">
          <v-list-item
            v-for="item in routeStandards"
            :key="item.text"
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon class="nav-honto navicon-std" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title light v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-subheader>
            <v-icon style="color:#d7d7d7; margin-left:-10px;">mdi-minus</v-icon>
            <span
              style="font-size:1rem;letter-spacing:.09rem;color:#d1d1d1;font-weight:500;margin-left:1.25rem;"
              >ORGANISATION</span
            >
          </v-subheader>--></v-list-item-group
        >
        <!-- <v-list-item-group class="pa-2">
          <v-list-item
            v-for="item in routeDienstplanung"
            :key="item.text"
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon class="nav-honto navicon-cal" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="">
              <v-list-item-title light v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
         <v-subheader>
            <v-icon style="color:#d7d7d7; margin-left:-10px;">mdi-minus</v-icon>
            <span
              style="font-size:1rem;letter-spacing:.09rem;color:#d1d1d1;font-weight:500;margin-left:1.6rem;"
              >SHAREPOINT</span
            >
          </v-subheader> 
        <v-list-item-group class="pa-2 pt-0" style="padding-top: 8px">
          <v-list-item
            class="sharepoint"
            href="http://sharepoint/ZKJMOH/Assistenten%20OH/SitePages/Homepage.aspx"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon class="nav-honto navicon-sharepoint"></v-icon>
            </v-list-item-icon>
            <v-list-item-content style="display: inline-flex !important">
              <v-list-item-title>Sharepoint</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider></v-divider>-->

      </v-list>


      <v-footer
        style="background: none; font-size: 14px !important"
        class="mb-2 footer-flex"
        bottom
        fixed
        padless
      >
        <v-footer class="mx-2 footer-flex" fixed padless>
          <v-list-item-group
            class="pa-0 pt-0"
            style="width: 100%; padding-top: 8px; display: block !important"
          >
          <v-list-item
            class="sharepoint"
            href="http://docs.honto-health.com"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon class="nav-honto navicon-support"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hilfe & Training</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
            <v-list-item @click="logout()" class="logout">
              <v-list-item-icon>
                <v-icon class="nav-honto navicon-logout"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="color: #ff5252"
                  >Logout</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <!--     <span>©honto healthcare</span> -->
          <v-subheader
            class="ma-0 pa-0"
            style="white-space: nowrap !important; font-size: 12px; left: 0rem"
          >
            <div></div>
            <span class="ml-2">v.1.0</span>
            <span
              style="
                white-space: nowrap !important;
                margin-left: 1.6rem !important;
              "
              >Januar 2023</span
            ><span
              class="pa-0 ma-0"
              style="
                margin-left: 1.6rem !important;
                white-space: nowrap !important;
              "
              >© honto</span
            ></v-subheader
          >
        </v-footer>
      </v-footer>
    </v-navigation-drawer>
    <router-view />
  </div>
</template>

<script>
import Auth from "../utils/Auth";
import { getCookie } from "../utils/CookieHelper";
export default {
  name: "DefaultLayout",
  created() {
    this.$store.dispatch("strapi/getMe");
  },
  computed: {
    isComputer() {
      return this.$vuetify.breakpoint.name !== "xs";
    },
    switchLabel: function() {
      return this.darkMode ? "Tagdienst" : "Nachtdienst";
    },
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    authed(isAuthed, wasAuthed) {
      this.drawer = isAuthed;
    },
  },
  mounted() {
    let cookie;
    if (typeof window !== "undefined") {
      cookie = getCookie("jwt");
    }
    this.authed = !!cookie;
  },
  updated() {
    this.authed = Auth.isAuthed();
  },
  data() {
    return {
      authed: false,
      drawer: this.$vuetify.breakpoint.mdAndUp,
      mini: true,

      routePINA: [
        {
          icon: "navicon-pina",
          text: "PINA Notaufnahme",
          href: "/pina",
        },
        //
        //   {
        //      icon: "navicon-neo",
        //      text: "P4 Neonatologie",
        //      href: "/Neonatologie",
        //    },

        //          {
        //        icon: "navicon-p3",
        //        text: "P3 Intensivstation",
        //       href: "/P3",
        //     },
      ],
      routeStandards: [
 //       {
   //      icon: "navicon-std",
    //      text: "Standardbibliothek",
     //     href: "/Sop-Gesamt",
      //  },
        // {
        // icon: "navicon-std",
        // text: "Admin",
        // href: "/Adminarea",
        // },
      ],
      routeDienstplanung: [
        {
          icon: "navicon-cal",
          text: "Dienstplanung",
          href: "/Dienstplan",
        } /**
        {
          icon: "",
          text: "E-Larning",
          href: "/E-Learning"
        }*/,
        //    {
        //   icon: "mdi-presentation",
        //   text: "Schlaue-Olga",
        //    href: "/Schlaue-Olga"
        //   }
      ],
    };
  },
  methods: {
    logout() {
      Auth.get().logout();
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--dark .footertext {
  color: var(--accent-color) !important;
}
.theme--dark .v-list .v-list-item--active .v-icon {
  color: var(--border-color);
}
.theme--dark .v-list .v-list-item .v-icon {
  color: rgb(61, 137, 154);
}
.theme--light.v-navigation-drawer {
  background: #fff !important;
}
.theme--dark .v-navigation-drawer {
  background: var(--background-color-secondary) !important;
  border-right: var(--border-color) solid 1px;
}
.theme--dark .v-image.v-responsive {
  filter: invert(1) !important;
}
.theme--dark .v-subheader {
  opacity: 0.3;
}

.theme--dark .v-list-item__title {
  color: white !important;
}
.footer-flex {
  display: flex !important;
  justify-content: space-between;
  margin: 1rem;
  font-size: 10px;
  background: none !important;
}
.v-list-item__content {
  font-weight: 400 !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #f5f5f500 !important;
}

.v-list-item__title {
  align-self: center;
  font-size: 0.9rem !important;
  color: #111;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 20px !important;
  padding: 0 16px;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 1 !important;
}
/**
.v-icon.v-icon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  font-size: 14px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: -10px;
}
/** */
.v-item-group {
  margin: 0px 0px;
}

.theme--light.v-list {
  color: rgba(58, 137, 153, 1) !important;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.15 !important;
}
.theme--light.v-list-item--active.sharepoint:hover::before,
.theme--light.v-list-item--active.sharepoint::before,
.theme--light.v-list-item-title--active.sharepoint::before {
  opacity: 0 !important;
  color: #333 !important;
}
.v-list-item {
  min-height: 18px !important;
}
.v-list-item__content {
  padding: 8px 0 !important;
}
.v-list-item__icon {
  margin: 7px -10px !important;
}

.navicon-its {
  line-height: 1.4;
  font-weight: 400;
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-its:hover {
  transform: scale(1.01);
  transition: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition-duration: 0.1s;
  color: blue !important;
}
.nav-honto {
  height: 32px;
  background-size: 32px 32px;
  width: 32px;
  position: relative;
  margin-right: 18px;
  left: 0rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.honto-logo {
  transform: scale(0.85);
  transition: none !important;
  * {
    transition: none !important;
  }
}
.honto-logo:hover {
  // opacity: 0.8;
  transform: scale(0.85);
  font-weight: 400 !important;
  cursor: pointer;
}
.navicon-pina::before {
  content: " ";
  background-image: url("../img/nav-pina.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-neo::before {
  content: " ";
  background-image: url("../img/nav-neo.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-p3::before {
  content: " ";
  background-image: url("../img/nav-p3.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;

  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-std::before {
  content: " ";
  background-image: url("../img/nav-std.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-cal::before {
  content: " ";
  background-image: url("../img/nav-cal.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-sharepoint::before {
  content: " ";
  background-image: url("../img/nav-sharepoint.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;

  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-support::before {
  content: " ";
  background-image: url("../img/nav-support.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;

  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-p2::before {
  content: " ";
  background-image: url("../img/nav-p2.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;

  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.navicon-logout::before {
  content: " ";
  background-image: url("../img/logout.svg");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  position: relative;
  left: -0.2rem;

  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  filter: invert(66%) sepia(57%) saturate(7443%) hue-rotate(332deg)
    brightness(106%) contrast(103%);
}
.theme--dark .navicon-pina::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.theme--dark .navicon-neo::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.theme--dark .navicon-p2::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.theme--dark .navicon-p3::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.theme--dark .navicon-std::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.theme--dark .navicon-cal::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.theme--dark .navicon-sharepoint::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.theme--dark .navicon-support::before {
  filter: invert(49%) sepia(78%) saturate(2096%) hue-rotate(183deg)
    brightness(96%) contrast(99%) !important;
}
.v-list-item .navspacing {
  margin: -15px 0 !important;
}
.app-title:hover {
  color: inherit;
}
.breadcrumbs .theme--light.v-divider {
  border-color: rgba(255, 255, 255, 0.29) !important;
}
.v-list-nav {
  padding: 0;
  margin: 0;
}
.nav-bg {
  background: red !important;
}
.theme--light .v-list.nav-bg.v-sheet {
  color: rgb(58, 137, 153) !important;
  background: white !important;
}
.theme--light .v-navigation-drawer .v-list:not(.v-select-list) {
  background: white !important;
}
.navigationlinks.theme--light.v-list-item--active::before {
  border-radius:6px!important;
  background:#999;
}
</style>
