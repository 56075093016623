<template>
  <v-dialog
    style="border-radius: 0px !important"
    v-model="dialog"
    max-width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="light-blue darken-1"
        text
        rounded
        icon
        class="lstight my-1 mr-.5"
        style="
          text-transform: none !important;
          height: 34px;
          font-weight: 400;
          font-size: 1rem !important;
        "
        elevation="0"
        v-bind="attrs"
        v-on="on"
        ><div class="actionicon icon-edit"></div>
      </v-btn>
    </template>
    <v-card tile elevation="0" class="pa-6 honto-border bg-card">
      <v-img
        gradient="to right, teal, #00000000"
        class="std-img px-6"
        style="border-radius: 8px 8px 0px 0px !important"
      >
        <v-card-title
          style="font-size: 1.8rem; font-weight: 400 !important"
          class="mt-6 pa-0 font-weight-regular justify-space-between"
        >
          <span>Standard aktualisieren</span>
          <v-row class="ml-2" justify="start" align="start"></v-row>
          <v-chip
            close
            color="white"
            text-color="error "
            @click="dialog = false"
            @click:close="dialog = false"
          >
            Schließen
          </v-chip>
        </v-card-title>
        <v-card-subtitle style="width: 80% !important" class="mt-2 px-0">
          Administratoren können bestehende Standards & SOPs hier editieren.<br />Ergänzen
          Sie Metadaten wie Suchwörter oder Gültigkeitsdaten oder laden sie
          einen aktualisierten Standard hoch.
        </v-card-subtitle>
      </v-img>
      <v-form v-model="valid" @submit.prevent="updateStandard">
        <v-row
          class="mt-4 py-4"
          style="height: 100px !important; border: red !important"
          align="start"
        >
          <v-col class="mb-0 pb-0">
            <v-text-field
              class="actionicon nameicon"
              color="primary"
              name="name"
              label="Name des Standards"
              v-model="payload.name"
              background-color="#ffffff80"
              solo
              flat
            ></v-text-field></v-col
          ><v-col>
            <v-file-input
              v-model="payload.file"
              name="file"
              label="Datei hinzufügen"
              background-color="#ffffff80"
              solo
              flat
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0">
          <v-col class="mb-0 pb-0">
            <v-select
              class="stdupload actionicon tagicon"
              v-model="payload.category"
              label="Zugehörige Klinik auswählen"
              name="category"
              readonly
              background-color="#ffffff80"
              solo
              flat
              style="box-shadow: none !important"
              elevation="0"
              :items="category"
            >
              <v-chip></v-chip>
            </v-select>
          </v-col>
          <v-col>
            <v-menu
              ref="menu1"
              v-model="datePickerMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(payload.validUntil)"
                  label="Gültig bis"
                  name="validUntil"
                  background-color="#ffffff80"
                  solo
                  flat
                  v-bind="attrs"
                  v-on="on"
                  class="actionicon calicon"
                />
              </template>
              <v-date-picker
                v-model="payload.validUntil"
                no-title
                @input="datePickerMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-divider style="margin-top: -1rem" class="mb-3"></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col class="pa-0">
            <v-container fluid class="mx-0 px-0">
              <v-combobox
                class="mx-0 combochips actionicon visiblekeywords"
                name="keywords"
                v-model="payload.keywords"
                filled
                hide-selected
                label="In Suchmaske angezeigte Stichpunkte (maximal 3)"
                multiple
                background-color="#ffffff80"
                persistent-hint
                chips
                deletable-chips
              >
              </v-combobox>
              <v-combobox
                class="mx-0 combochips actionicon hiddenkeywords"
                v-model="payload.keywords_hidden"
                name="keywords_hidden"
                filled
                background-color="#ffffff80"
                hint="Diese Begriffe können als Suchbegriffe verwendet werden, werden aber in der für die Endnutzer angezeigten Suchmaske nicht angezeigt."
                hide-selected
                label="Fügen Sie Stichworte & Synonyme für die Suche hinzu"
                multiple
                chips
                deletable-chips
              >
              </v-combobox></v-container></v-col
        ></v-row>

        <v-divider class="mb-8"></v-divider>

        <v-card-actions class="ma-0 pa-0">
          <v-spacer></v-spacer>
          <v-btn
            color="dark"
            class="ml-8 px-6 py-6 pr-9"
            dark
            type="submit"
            elevation="0"
          >
            <div class="actionicon icon-std-edit"></div>
            Standard Aktualisieren
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import { CMS_URL } from "../api";
import FetchService, { FetchMethod } from "../api/FetchService";
import { getCookie } from "../utils/CookieHelper";

export default {
  props: ["standard"],
  data: (vm) => ({
    valid: false,
    dialog: false,
    payload: {
      ...vm.standard,
      validUntil: moment(vm.standard.validUntil, "DD.MM.YY").format(
        "YYYY-MM-DD",
      ),
      file: null,
    },
    category: ["PINA"], //, "Neonatologie", "Intensivmedizin", "Onkologie"],
    datePickerMenu: false,
  }),
  computed: {
    user() {
      return this.$store.state.strapi.user;
    },

    currentTitle() {
      switch (this.step) {
        case 1:
          return "Standard Anpassen";
        case 2:
          return "Standard Hinzufügen";
        default:
          return "Account created";
      }
    },
  },
  watch: {
    ["payload.validUntil"](val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    async updateStandard(e) {
      e.preventDefault();
      if (this.payload.file) {
        const form = new FormData();
        form.append("files", this.payload.file);
        form.append("ref", "api::standard.standard");
        form.append("refId", this.payload.id);
        form.append("field", "file");

        await FetchService.fetchApi(
          FetchMethod.post,
          CMS_URL + "upload",
          form,
          {
            Authorization: "Bearer " + getCookie("jwt"),
          },
        );
      }

      const response = await FetchService.fetchApi(
        FetchMethod.put,
        CMS_URL + "standards/" + this.payload.id,
        {
          data: {
            name: this.payload.name,
            validUntil: this.payload.validUntil,
            keywords: this.payload.keywords.join(", "),
            keywords_hidden: this.payload.keywords_hidden.join(", "),
          },
        },
      );
      this.$emit("updated", response.data);
      this.dialog = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style lang="scss">
.v-chip--select.v-chip:hover {
  background: tomato !important;
  color: white !important;
  .v-icon.v-icon--right {
    color: white !important;
    transition: none !important;
  }
}

.stdupload .v-select__selection {
  font-weight: 500 !important;
  text-transform: uppercase;
  color: rgb(92, 146, 255);
  letter-spacing: 0.05rem;
  font-size: 0.8rem;
}
.v-autocomplete.v-input > .v-input__control > .v-input__slot:hover {
  background: #f5f5f5 !important;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
  font-weight: 500;
}
.std-img {
  background: url("https://images.unsplash.com/photo-1643228995868-bf698f67d053?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80");
  background-size: cover;
  background-position: center bottom;
  height: 180px;
  color: white !important;
}

.actionicon:before {
  content: " ";
  background-size: 22px 22px;
  height: 22px;
  width: 22px;
  position: relative;
  margin-right: 0rem;
  top: 0px !important;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.icon-edit:before {
  background-image: url("../img/icon-edit-square.svg");
  left: 0px !important;
  transform: scale(1.1);
}

.icon-std-edit:before {
  background-image: url("../img/icon-std-edit.svg");
  transform: scale(1.45);
  margin-right: 0.75rem !important;
}
.calicon:before {
  background-image: url("../img/icon-edit-std-date.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  top: 0.75rem !important;
  opacity: 0.6;
}
.tagicon:before {
  background-image: url("../img/icon-edit-std-tag.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  top: 0.75rem !important;
  opacity: 0.6;
}
.nameicon:before {
  background-image: url("../img/icon-edit-std-text.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  top: 0.75rem !important;
  opacity: 0.6;
}
.hiddenkeywords:before {
  background-image: url("../img/icon-edit-std-hidden.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  top: 0.75rem !important;
  opacity: 0.6;
}
.visiblekeywords:before {
  background-image: url("../img/icon-edit-std-visible.svg");
  transform: scale(1.3);
  margin-right: 1rem !important;
  top: 0.75rem !important;
  opacity: 0.6;
}
.combochips .v-chip--select.v-chip {
  border: 1px solid rgb(194, 194, 194) !important;
}
.combochips.v-autocomplete.v-input > .v-input__control > .v-input__slot:hover {
  background: #ffffffaa !important;
}
.v-input.v-text-field > .v-input__control > .v-input__slot:hover {
  background: #ffffffaa !important;
}
</style>
