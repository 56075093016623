<template>
  <div class="newsbg component-bg honto-border honto-shadow">
    <v-row dense style="border-bottom: 1px solid #ebebeb70">
      <v-col cols="12" sm="12" class="d-flex justify-space-between">
        <div
          class="lstight"
          style="font-weight: 500 !important; font-size: 1.4rem"
        >
          Neuigkeiten PINA
        </div>

        <v-chip
          small
          to="/neuigkeiten"
          text-color="primary"
          color="primary lighten-3"
          text
          outlined
          style="text-transform: uppercase"
          ><v-icon class="pr-1" x-small>mdi-call-made</v-icon> alle</v-chip
        >
      </v-col>
    </v-row>
    <v-row
      v-if="newses"
      class="mt-3"
      style="display: flex; flex-direction: row; flex-wrap: wrap"
    >
      <v-col v-for="news in newses.data" :key="news.id" cols="12" md="6">
        <v-dialog
          scrollable
          transition="dialog-top-transition"
          max-width="800"
          style="background: white !important"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-bind="attrs"
              v-on="on"
              flat
              tile
              class="newstile pt-0 mt-0"
            >
              <v-row>
                <v-col cols="12" sm="4" style="min-height: 150px">
                  <!--   <v-img
                style=" aspect-ratio: 16 / 9;!important;"
                min-height="120"
                :src="item.title"
                >
                -->
                  <v-img
                    :src="`${news.attributes.hero.data.attributes.url}`"
                    gradient="to bottom right, rgba(0,0,0,.3), rgba(255,255,255,.001)"
                    class="white--text align-end" style="border-radius:6px;"
                    min-height="134px"
                  >
                    <v-card-title class="white--text mt-8">
                      <div
                        color="white"
                        style="position: absolute; left: 4px; top: 4px;"
                      >
                        <div class="card-header-date" style="border-radius:6px;">
                          <div class="card-header-date-day">
                            {{ getDay(news.attributes.createdAt) }}
                          </div>
                          <div class="card-header-date-month">
                            {{ getMonth(news.attributes.createdAt) }}
                          </div>
                        </div>
                      </div>
                    </v-card-title>
                  </v-img>
                </v-col>
                <v-col cols="6" sm="8">
                  <p
                    class="lstight"
                    style="font-weight: 600; font-size: 0.95rem"
                  >
                    {{ news.attributes.title }}
                  </p>
                  <p
                    class="newsexerpt"
                    style="
                      font-size: 0.75rem;
                      padding-right: 10px;
                      padding-top: 4px;
                      color: rgb(107 114 128) !important;
                    "
                  >
                    {{ news.attributes.exerpt }}
                  </p>
                  <a
                    style="
                      margin: 10px 0 20px 0;
                      font-size: 0.8rem;
                      color: #2196f3 !important;
                    "
                    ><v-icon small color="#2196f3">mdi-arrow-right</v-icon> mehr
                    lesen</a
                  >

                  <v-avatar
                    color="none"
                    text="white"
                    style="
                      border: 1px solid #d3d3d3;
                      position: absolute;
                      right: 10px;
                      bottom: 10px;
                    "
                    size="34px"
                  >
                    <!-- {{news.attributes.hero}} -->

                    <img src="../img/rchrt.jpg" alt="John" />
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <template v-slot:default="dialog">
            <v-card
              style="
                border-radius: 3px !important;
                max-height: 100vh;
                padding: 0 2rem;
              "
            >
              <v-toolbar
                light
                style="
                  border-bottom: 1px solid #e5e5e5;
                  font-weight: 700;
                  font-size: 1.2rem;
                  margin-top: 0.75rem;
                  padding-bottom: 4rem;
                "
                ><v-row>{{ news.attributes.title }}</v-row>

                <v-avatar
                  color="none"
                  text="white"
                  style="
                    border: 1px solid #ddd;
                    margin-right: 0.4rem;
                    color: black;
                    background: white;
                    font-size: 0.9rem;
                    font-weight: 300 !important;
                    border: 1px solid #d3d3d3;
                  "
                  size="38px"
                >
                  <img src="../img/rchrt.jpg" alt="John" />
                  <!--   {{news.attributes.authorShorthand}} --> </v-avatar
                ><span>
                  <span
                    style="
                      display: block;
                      font-size: 0.8rem;
                      font-weight: 600;
                      margin-bottom: -2px !important;
                      margin-top: 2px;
                    "
                    >{{ news.attributes.author }}</span
                  >
                  <span
                    style="
                      display: block;
                      font-size: 0.65rem;
                      color: #555;
                      margin-top: 0px;
                      font-weight: 500;
                      margin-top: -2px;
                    "
                    >{{ news.attributes.authorShorthand }}</span
                  >
                </span>
              </v-toolbar>
              <v-card-text>
                <div
                  class="pt-4 posti"
                  style="color:#222;font-size:1rem;line-height:1.7; margin:2rem -1rem!important;padding:0 !important;font-size: 1rem;
                        font-weight: 400;
                        max-height:100%    line-height: 1.5; 
                        border-radius:4px!important;
                        letter-spacing: 0;"
                  v-html="markedText(news.attributes.content)"
                ></div>
              </v-card-text>
              <v-card-actions
                style="
                  justify-content: space-between;
                  border-top: 1px solid #e5e5e5 !important;
                "
              >
                <div class="justify-start py-4">
                  <v-row><span></span> </v-row>
                </div>
                <!--               <div class="justify-start">
                        <v-row><span>
                                     <v-icon small
      class="pr-1 py-2"
    >
      mdi-calendar
    </v-icon>{{getFullDate(news.attributes.createdAt)}}
    
          </span><v-divider vertical></v-divider></v-row>
                      </div> -->

                <v-btn text @click="dialog.value = false">Schließen</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { marked } from "marked";
import qs from "qs";
import { fetchResource } from "../api";
import moment from "moment";

export default {
  name: "newses",

  data() {
    return {
      newses: null,
      loading: true,
      error: false,
    };
  },
  methods: {
    getMonth: (date) => {
      return moment(date).locale("de").format("MMM");
    },
    getDay: (date) => {
      return moment(date).locale("de").format("D");
    },
    getFullDate: (date) => {
      return moment(date).locale("de").format("MMMM D, YYYY");
    },
    markedText(text) {
      return marked.parse(text);
    },
  },
  async created() {
    const query = qs.stringify({
      populate: "hero",
    });
    this.newses = await fetchResource("newses", query);
    if (!this.newses.error) {
      this.loading = false;
    } else {
      this.error = true;
    }
  },
};
</script>
<style scoped lang="scss">
.v-card-text > img {
  max-width: 50px !important;
}
.newstile {
  background: white !important;
}
.newstile:hover {
  background: #f7f7f7 !important;
  border-radius:6px!important;
}
.component-bg {
  background: white;
  padding: 2rem;
  margin: 0;
  cursor: pointer;
}
.card-header-date,
.card-body-header-category {
  text-transform: uppercase;
  background-color: #fff;
  color: #333;
  font-weight: bold;
  text-align: center;
  right: 0;
  bottom: 0;
}

.card-header-date {
  margin: 8px;
  /* border-radius: 50%; */
  font-weight: bold;
  padding: 4px 9px;
  line-height: 15px;
}

.card-header-date-day {
  font-size: 15px;
}

.card-header-date-month {
  font-size: 9px;
}
.newsexerpt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

::v-deep .posti img {
  max-width: 100% !important;
  border-radius: 5px !important;
  margin: 1rem 0rem;
}
</style>
